@import 'src/assets/styles/include-media.scss';

/* * * * * * * * * * * * * * * * * * * * ** * * * *
* * * * * * * * * * * * * * * * * * * * * * * * * *
* Side Bar CSS
* * * * * * * * * * * * * * * * * * * * * * * * * *
* * * * * * * * * * * * * * * * * * * * * * * * * */

/* Solution for mac machine in order to avoid buttons highlighted when are clicked on it*/
div:focus {
  outline: none;
}

/* Desktop Declaration */
@media (min-width: 1025px) {
  .sidebar {
    position: absolute;
    top: 10px;
    bottom: 10px;
    overflow: hidden;
    z-index: 200;
    width: 390px;
    border: 0;
    box-shadow: rgba(0, 0, 0, 0.298039) 0 1px 4px -1px;
    border-radius: 0;

    &.collapsed,
    &.home.collapsed {
      height: 50px;
      width: 360px;
    }

    &.collapsed > .sidebar-content {
      overflow-y: hidden;
    }

    &.collapsed .sidebar-footer {
      visibility: hidden;
    }
  }

  .sidebar-left {
    left: 10px;

    .sidebar-header {
      padding-left: 0;
    }

    .sidebar-footer {
      width: 100%;
      text-align: center;
      margin-left: -10px;
      margin-right: -25px;
    }

    .sidebar-close {
      right: 0;
    }

    & ~ .spreo-map {
      margin-left: 40px;
    }

    .sidebar-tabs {
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      background-color: #fff;
    }

    .sidebar-tabs,
    .sidebar-tabs > ul {
      position: absolute;
      width: 40px;
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .sidebar-tabs > li,
    .sidebar-tabs > ul > li {
      width: 100%;
      height: 50px;
      color: #666;
      font-size: 12pt;
      overflow: hidden;
      transition: all 80ms;
      border-top-right-radius: 10px;
    }

    .sidebar-tabs > li:hover,
    .sidebar-tabs > ul > li:hover {
      color: #000;
      /*background-color: #fff9e6;*/
    }

    .sidebar-tabs > li.active,
    .sidebar-tabs > ul > li.active {
      color: #000;
    }

    .sidebar-tabs > li.disabled,
    .sidebar-tabs > ul > li.disabled {
      color: rgba(102, 102, 102, 0.4);
    }

    .sidebar-tabs > li.disabled > a,
    .sidebar-tabs > ul > li.disabled > a {
      cursor: pointer;
    }

    .sidebar-tabs > li > a,
    .sidebar-tabs > ul > li > a {
      display: block;
      width: 100%;
      height: 100%;
      line-height: 46px;
      color: inherit;
      text-decoration: none;
      text-align: center;
      font-size: 20px;
      margin-top: 5px;
    }

    .sidebar-tabs > ul + ul {
      bottom: 0;
    }

    .sidebar-content {
      left: 0px;
      right: 0;
    }
  }

  .spreo-bars {
    background-color: #fff;
    color: #0f0f0f !important;
    border: 2px solid #0f0f0f;
    border-left: 0;
    border-bottom-right-radius: 10px;
  }

  .bars-btn {
    background-color: #fff;
    width: 20px;
    height: 50px;
    overflow: hidden;
    transition: all 80ms;
    padding-top: 15px;
    line-height: 25px;
    padding-left: 20px;
    padding-right: 25px;
    margin-right: 10px;
    text-align: center;
    color: #000;
    cursor: default;

    &:hover {
      color: #000;
    }

    .dot-menu {
      width: 6px;
      display: inline-block;
      background-image: url(../../assets/images/others/menu_blue.png);
      background-repeat: no-repeat;
      background-size: 5px;
      height: 23px;
    }
  }

  .search-help .bars-btn {
    border-right: none !important;
    top: 10px;
    left: 6px;
    position: relative;
    padding: 10px;
  }

  .sidebar-content {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: hidden;
    font-family: 'Open Sans', sans-serif;

    &.home {
      background-color: #fff;
    }
  }

  .sidebar-pane {
    display: none;
    left: 0;
    right: 0;
    box-sizing: border-box;

    &.active {
      display: block;
      height: 100%;

      &.home {
        background-size: auto 100%;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    .sidebar-pane {
      min-width: 250px;
    }
  }
  @media (min-width: 1200px) {
    .sidebar-pane {
      min-width: 295px;
    }
  }

  .sidebar-header {
    height: 50px;
    line-height: 35px;
    font-size: 12.5pt;
    color: #fff;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 0 !important;
    margin-top: -10px;
  }

  #navigate {
    .sidebar-header {
      margin-right: 16px !important;
    }
  }

  .sidebar-footer {
    position: absolute;
    bottom: 0;
    height: 50px;
    align-content: center;
    margin-top: 0;
    margin-bottom: 20px;
    line-height: 50px;
  }

  @media (min-width: 768px) {
    .sidebar-left ~ .spreo-map {
      margin-left: 0;
    }
  }

  @media (min-width: 768px) {
    .sidebar-left {
      bottom: 0;
    }
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .sidebar .modal .modal-dialog {
    max-width: 98%;
  }
}

@media (min-width: 1200px) {
  .sidebar .modal .modal-dialog {
    max-width: 96%;
  }
}

.window-header {
  .window-header-left {
    flex-grow: 2;
    display: flex;

    .back-btn,
    .filter-btn {
      display: flex;
      align-items: center;
      height: 28px;
    }
  }
}

.sidebar-pane.find.active {
  height: 100% !important;
  display: flex !important;
  flex-flow: column !important;
}

#home-header {
  margin: -10px -10px 0;
}

#navigate-input::placeholder {
  color: #c8c8c8;
  opacity: 1;
}

/* Side Bar content Mobile */
@media (max-width: 1025px) {
  .sidebar {
    right: 10px;
    left: 10px;
    top: 20px;
    height: 99%;
  }

  #welcome-ui-window {
    left: 0;
    position: relative;
  }
  #find-header {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  #home-header {
    margin-left: -10px !important;
  }

  .sidebar-content {
    // font-family: 'Open Sans', sans-serif;
    position: absolute;
    left: 0;
    right: 0;
    background-color: #fff;
    overflow-y: hidden;
  }

  .sidebar-left .sidebar-content {
    top: 0;
    height: 100%;
    min-height: 50px;
  }

  .sidebar-right .sidebar-content {
    top: 0;
    bottom: 10px;
    min-height: 50px;
  }

  .sidebar.collapsed > .sidebar-content {
    overflow-x: hidden;
  }

  .sidebar-pane {
    display: none;
    top: 0;
    bottom: 0;
    box-sizing: border-box;
    padding: 10px;
    min-width: 200px;
  }

  .sidebar-pane.active {
    display: block;
    height: 90%;
  }

  .sidebar-pane.active.home {
    background-size: auto 100%;
  }

  .sidebar-header {
    margin: -10px -10px 0;
    height: 50px;
    line-height: 35px;
    font-size: 12.5pt;
    color: #fff;
    background-color: #fff;
    border-radius: 10px;
    border: 2px solid #0f0f0f;
  }

  .bars-btn .dot-menu {
    width: 6px;
    display: inline-block;
    background-image: url(../../assets/images/others/menu_blue.png);
    background-repeat: no-repeat;
    background-size: 5px;
    height: 23px;
  }

  .spreo-search {
  }

  .sidebar-left .sidebar-header {
    // padding-left: 20px;
  }

  .sidebar-close {
    position: absolute;
    top: 0;
    width: 40px;
    height: 40px;
    text-align: center;
    cursor: pointer;
  }

  .sidebar-left .sidebar-close {
    right: 0;
  }

  .sidebar-right .sidebar-close {
    left: 0;
  }
}

.sidebar .modal-calendar.modal .modal-dialog {
  max-width: 285px;
}

/**
 * Modal window styles
 */
.sidebar .modal,
.sidebar .modal-backdrop {
  position: absolute !important;
}

.sidebar .modal-backdrop {
  top: 50px;
  opacity: 0.7;
}

.sidebar .modal .modal-dialog {
  max-width: 98%;
  top: 10px;
}

.sidebar .modal .modal-dialog .modal-content {
  border-radius: 0;
}

.sidebar .modal .modal-dialog .modal-content .modal-title {
  text-transform: uppercase;
}

.sidebar .modal .modal-dialog .modal-content .modal-header,
.sidebar .modal .modal-dialog .modal-content .modal-footer {
  padding: 10px 15px;
}

.sidebar-close-btn {
  background-color: #0f0f0f;
  font-size: 28px;
  position: absolute;
  color: #fff;
  top: 11px;
  padding: 0px 7px;
  right: 1%;
  border: 0;
  font-weight: 800;
}

.srch-form {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 0;
  box-shadow: none;
  padding-left: 5px;
  font-size: 16px;
}

.srch-form[disabled] {
  background-color: #fff;
}

.srch-form:focus {
  border: 0;
  box-shadow: none;
}

input.srch-form::placeholder {
  color: #c8c8c8;
  font-weight: lighter;
  font-size: 14px;
}

@media (max-width: 767px) {
  input.srch-form::placeholder {
    font-size: 15px;
  }
}

.spreo-search.nav {
  margin-left: -5px;
}

.spreo-search.nav.right-dir {
  left: -15px;
}

.spreo-search.nav.to-nav {
  margin-bottom: 10px;
}

.sidebar-header .input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}

.sidebar-header .input-group {
  position: relative;
  display: table;
  border-collapse: separate;
  width: unset;
}

.sidebar-header .input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}

.srch-close-btn.right-dir {
  padding-left: 15px;
}

.srch-close-btn span {
  font-size: 30px;
  bottom: 10px;
  position: relative;
}

.srch-btn,
.spreo-filter,
.srch-close-btn {
  border-radius: 0;
  border: 0;
  color: #0f0f0f;
}

.srch-btn {
  padding-right: 15px;
}

.srch-btn:hover,
.srch-btn:focus,
.srch-btn:active,
.srch-close-btn:hover,
.srch-close-btn:focus,
.srch-close-btn:active,
.spreo-filter:hover,
.spreo-filter:focus,
.spreo-filter:active {
  background-color: initial !important;
  border-color: initial !important;
  box-shadow: none !important;
  outline: none !important;
  color: #0f0f0f;
}

.srch-btn.mobile,
.srch-close-btn.mobile {
  background-color: #0f0f0f;
  border-color: #0f0f0f;
}

#favorites-close {
  left: -40px;
  top: 8px;
}

.sidebar-header .btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  font-size: 19px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sidebar-content .nav-close-btn .close-nav-icon {
  color: #0f0f0f;
}

.window-header {
  display: flex;
  justify-content: space-between;
  margin: -8px 0 10px;

  #header {
    font-weight: 700;
  }
}

@media (max-width: 767px) {
  .sidebar {
    background: #fff;
    z-index: 301;
    top: 10px;
    right: 0;
    height: 100%;
    transition: all 300ms;
  }
  .sidebar-right ~ .spreo-map .gm-style > div.gmnoprint[style*='right: 28px'],
  .sidebar-right ~ .spreo-map #floating-panel {
    margin-right: 5px;
  }
}

.sidebar-header {
  border: none;
  box-shadow: 0 0 6px #00000029;

  /* .btn {
    margin-bottom: 15px;
  } */
}

.sidebar-right {
  bottom: 2px;
}

.search-help {
  text-align: center;
  margin-top: 25px;
  font-size: 14px;
  color: #000;
  font-weight: lighter;
}

.sidebar-right .sidebar-header {
  min-height: 50px;
}

.spreo-ui-window {
  height: 92%;
  overflow-y: auto;
  margin-top: 10px;
}

.back-btn-ico {
  background-image: url(../../assets/images/others/back-btn-ico.svg);
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: center;
  height: 15px;
  width: 18px;
  display: inline-block;
  position: relative;
  top: 5px;

  @include media('<=phone') {
    top: 6px;
  }
}

.back-btn {
  background-color: #fff;
  color: #0f0f0f;
  border: 0;
  height: 45px;
  width: 40px;
}

.sidebar-header {
  .filter-btn-ico {
    background-image: url('../../assets/images/others/filter.svg');
    background-position: center;
    background-repeat: no-repeat;
    height: 24px;
    width: 24px;
    padding: 0;
    margin: 0;
    display: block;
  }
}

#home-ui-window,
#calendar-ui-window {
  margin-top: 10px;
  margin-bottom: 60px;
  overflow: hidden;
}

#home-ui-window .menu-items {
  margin-left: -10px;
  margin-right: -10px;
  border-bottom: 1px solid #ccc;
  padding: 20px 10px 25px;
}

#home-ui-window .menu-items .row {
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 40px;
}

#home-ui-window .menu-items .row .col-md-4 {
  padding-left: 10px;
  padding-right: 10px;
}

#home-ui-window .menu-items .row > div > div {
  cursor: pointer;
}

#home-ui-window .menu-items a {
  display: inline-block;
}

#home-ui-window .menu-items .home-menu-icons.categories-ico {
  background-image: url(../../assets/images/home-screen/categories-ico.svg);
}

#home-ui-window .menu-items .home-menu-icons {
  display: block;
  width: 54px;
  height: 54px;
  margin: 0 auto;
  background-repeat: no-repeat;
}

#home-ui-window .menu-items .home-menu-icons + span {
  display: inline-block;
  margin-top: 4px;
  font-size: 13px;
  color: #000000;
}

#home-ui-window .menu-items .home-menu-icons.favorites-ico {
  background-image: url(../../assets/images/home-screen/favorites-ico.svg);
}

#home-ui-window .menu-items .home-menu-icons.parking-ico {
  background-image: url(../../assets/images/home-screen/parking-ico.svg);
}

#home-ui-window .menu-items .home-menu-icons.buildings-ico {
  background-image: url(../../assets/images/home-screen/buildings-ico.svg);
}

#home-ui-window .menu-items .home-menu-icons.campuses-ico {
  background-image: url(../../assets/images/home-screen/campuses-ico.svg);
}

#home-ui-window .menu-items .home-menu-icons.navigate-ico {
  background-image: url(../../assets/images/home-screen/navigate-ico.svg);
}

#home-ui-window .menu-items .home-menu-icons.help-ico {
  background-image: url(../../assets/images/home-screen/help-ico.svg);
}

#home-ui-window .menu-items .row:last-child {
  margin-bottom: 0;
}

#home-ui-window .additional-home-btns {
  padding: 15px 10px;
}

#home-ui-window .additional-home-btns a {
  color: #000;
  display: block;
  margin-bottom: 15px;
  width: 100%;
}

#home-ui-window .additional-home-btns a i {
  margin-right: 15px;
}

.report-issue-ico {
  width: 22px;
  display: inline-block;
  background-image: url(../../assets/images/others/report-issue.svg);
  background-repeat: no-repeat;
  height: 19px;
  position: relative;
  top: 5px;
}

.logout-ico {
  width: 22px;
  display: inline-block;
  background-image: url(../../assets/images/home-screen/logout-ico.svg);
  background-repeat: no-repeat;
  background-position: center;
  height: 23px;
  position: relative;
  top: 8px;
}

@media (max-width: 670px) {
  .form-control {
    padding-left: 15px;
  }
}

#building .modal-content,
#campus-map .modal-content {
  width: 340px;
  margin: auto;
}

#building .campus-modal ul,
#campus-map .campus-modal ul {
  margin: 15px 15px !important;
}

.select-one ul,
#campus-map .campus-modal ul,
#building .campus-modal ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

#building .campus-modal ul li,
#campus-map .campus-modal ul li {
  text-align: center;
  margin-bottom: 5px;
}

#building .campus-modal ul li a,
#campus-map .campus-modal ul li a {
  font-weight: 500;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #337ab7;
}

.campus-modal.finish {
  padding-top: 33px;

  .exit-navigation-btns {
    margin-top: 23px;
  }
}

.remeber {
  padding: 0 15px 0;
  margin: 0 15px 0;
}

.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
  color: #000000;
}

.default-label {
  font-weight: 500;
  font-size: 14px;
  margin: 0;
}

.remeber label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 500;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.styled-checkbox {
  position: absolute;
  opacity: 0;
}

.styled-checkbox:checked + label:before {
  background: #000;
}

.styled-checkbox + label:before {
  content: '';
  margin-right: 7px;
  display: inline-block;
  vertical-align: text-top;
  width: 16px;
  height: 16px;
  background: white;
  border: 1px solid #000;
  margin-top: 2px;
}

.styled-checkbox:checked + label:after {
  content: '';
  position: absolute;
  left: 3px;
  top: 9px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}

.sidebar-left {
  left: 0;
  top: 0;
}

.sidebar-left.collapsed {
  top: 20px;
  left: 20px;
}

.home.active #home-header {
  display: none;
}

.collapsed .sidebar-pane.active .sidebar-header {
  box-shadow: 0 0 6px #00000029;
  display: block !important;
}

.sidebar-pane.active {
  padding-right: 16px;
  padding-top: 27px;
  padding-left: 16px;
}

.collapsed .sidebar-pane.active {
  padding: 10px;
  padding-right: 0;
}

.sidebar-pane.active.poi-card {
  padding-left: 0;
  padding-right: 0;

  .sidebar-header {
    margin-left: 16px;
    margin-right: 16px !important;
  }

  .spreo-ui-window {
    margin-top: 15px;
  }
}

.menu-btn {
  position: fixed;
  width: 56px;
  height: 56px;
  left: 20px;
  bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 8.85px rgba(0, 0, 0, 0.160784);
  border-radius: 28px;
  z-index: 10;
  background-image: url('../../assets/images/others/menu_36.svg');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  @include media('<=phone') {
    right: 17px;
    left: auto;
    bottom: 60px;
  }
}

.main-menu-container {
  .poi-menu-container {
    margin-top: 0px !important;
    display: none !important;
  }

  h4 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
    flex: none;
    order: 1;
    flex-grow: 1;
    margin: 0px 14px;
  }

  .menu-btns-container {
    display: flex;
    margin-top: 60px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  .main-menu-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 16px 8px;
    width: 227px;
    height: 39px;
    margin-bottom: 8px;

    &.report-issues-btn,
    &.help-instructions-btn,
    &.staff-login-btn {
      background: #ffffff;
      border: 1px solid #c8c8c8;
      box-sizing: border-box;
      border-radius: 8px;

      span {
        color: black;
      }
    }

    &.report-issues-btn,
    &.help-instructions-btn {
      display: none;
    }

    &.logout-btn {
      background: #ffffff;
      border: 1px solid #f49322;
      box-sizing: border-box;
      border-radius: 8px;
    }
  }

  .close-main-menu-btn {
    display: block;
    position: absolute;
    width: 56px;
    height: 56px;
    left: 20px;
    bottom: 20px;
    background: #ffffff;
    box-shadow: 0px 0px 8.85px rgba(0, 0, 0, 0.160784);
    border-radius: 28px;
    background-image: url('../../assets/images/others/close-main-menu.svg');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    @include media('<=phone') {
      right: 16px;
      left: auto;
      bottom: 62px;
    }
  }
}

.sidebar.collapsed {
  border-radius: 10px !important;
}

.sidebar.collapsed .close-main-menu-btn {
  display: none;
}

.btns-ico {
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
  margin-right: 8px;

  &.staff-login-ico {
    background-image: url('../../assets/images/home-screen/staff_login.svg');
  }

  &.warning-ico {
    background-image: url('../../assets/images/others/warning.svg');
  }

  &.question-ico {
    background-image: url('../../assets/images/others/question.svg');
  }

  &.logout-ico {
    background-image: url('../../assets/images/others/logout.svg');
    margin-bottom: 14px;
  }
}

.campuses-item-container {
  display: flex;
  align-items: baseline;
  justify-content: space-evenly;

  .campuses-edit-btn {
    background-image: url('../../assets/images/others/edit-campuses.svg');
    background-position: center;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    cursor: pointer;
    outline: none;
  }

  .campuses-item {
    margin-top: 30px;
    width: 85%;
  }
}

.__select {
  position: relative;

  &[data-state='active'] {
    .__select__title {
      &::before {
        transform: translate(-3px, -50%) rotate(-45deg);
      }

      &::after {
        transform: translate(3px, -50%) rotate(45deg);
      }
    }

    .__select__content {
      opacity: 1;
    }

    .__select__label + .__select__input + .__select__label {
      max-height: 40px;
      border-top-width: 1px;
    }
  }
  &[data-state=''] {
    .__select__content {
      display: none;
    }
  }
}
.__select__title {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 8px 16px;

  border-radius: 5px;
  border: solid 1px #c7ccd1;

  cursor: pointer;

  i {
    background-image: url('../../assets/images/others/Buildings.svg');
    background-position: center;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
  }

  span {
    margin-left: 10px;
    display: flex;
    flex-direction: column;

    span,
    small {
      margin-left: 5px;
    }
  }

  &::before,
  &::after {
    content: '';

    position: absolute;
    top: 50%;
    right: 16px;

    display: block;
    width: 10px;
    height: 2px;

    transition: all 0.3s ease-out;

    background-color: #333333;

    transform: translate(-3px, -50%) rotate(45deg);
  }
  &::after {
    transform: translate(3px, -50%) rotate(-45deg);
  }

  &:hover {
    border-color: #5fac45;

    &::before,
    &::after {
      background-color: #5fac45;
    }
  }
}
.__select__content {
  position: absolute;
  top: 54px;
  left: 4px;

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.160784);
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  width: calc(100% - 6px);

  background-color: #ffffff;

  border: 1px solid #c7ccd1;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  transition: all 0.3s ease-out;

  opacity: 0;
  z-index: 8;
  max-height: 320px;

  span {
    width: 100%;
    height: 40px;
    z-index: 0;
  }
}
.__select__input {
  display: none;

  &:checked + label {
    background-color: #dedede;
  }
  &:disabled + label {
    opacity: 0.6;
    pointer-events: none;
  }
}
.__select__label {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 16px;
  border-bottom: 0.25px solid #c4c4c4;
  margin-bottom: 0 !important;

  transition: all 0.2s ease-out;

  cursor: pointer;

  overflow: hidden;

  & + input + & {
    border-top: 0 solid #c7ccd160;
  }

  &:hover {
    background-color: #5fac45 !important;

    color: #ffffff;
  }
}

.main-menu-list {
  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    position: static;
    width: 100%;
    height: 56px;
    left: 0px;
    top: 0px;
    background: #ffffff;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 15px -25px;
    cursor: pointer;
    border-bottom: 1px solid #c9c5c5;

    &:last-child {
      border-bottom: none;
    }

    .interactive-element {
      display: flex;
      align-items: center;
      width: 100%;
    }

    i {
      background-position: center;
      background-repeat: no-repeat;
      width: 36px;
      height: 36px;

      &.categories-ico {
        background-image: url(../../assets/images/home-screen/categories_ico.svg);
      }

      &.buildings-ico {
        background-image: url(../../assets/images/home-screen/buildings-ico.svg);
      }

      &.navigate-ico {
        background-image: url(../../assets/images/home-screen/navigate-ico.svg);
      }

      &.favorites-ico {
        background-image: url(../../assets/images/home-screen/favorites-ico.svg);
      }

      &.campuses-ico {
        background-image: url(../../assets/images/home-screen/campuses-ico.svg);
      }

      &.parking-ico {
        background-image: url(../../assets/images/home-screen/parking-ico.svg);
      }

      &.help-ico {
        background-image: url(../../assets/images/home-screen/help-ico.svg);
      }
    }

    span {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: #4d4d4d;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 0px 12px;
    }
  }
}

#report_an_issue {
  width: 416px;

  .modal-content {
    justify-content: center;
    align-content: center;
    min-height: 392px;
    border: none;
    border-radius: 0;
  }

  .campus-modal {
    h4 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: #000000;
      margin: 0px 10px;
      margin-top: 30px;
    }
  }

  .modal-report-issue-ico {
    display: block;
    margin-left: 48%;
    margin-top: 15px;
  }

  .report-issue-form {
    margin-top: 20px;
    margin-left: 28px;
    margin-bottom: 20px;

    ul {
      order: 3;

      .selected-file {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        margin: -10px;
        margin-left: 0;

        span {
          width: 70%;
          text-align: left;
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
        }

        i {
          width: 30%;
          text-align: right;
          margin-right: 30px;
          font-size: 18px;
          cursor: pointer;
        }
      }
    }

    .issue-file {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    span {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: #000000;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      margin: 12px 0px;
    }

    textarea {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      padding: 8px;
      position: static;
      width: 360px;
      height: 109px;
      left: calc(50% - 360px / 2);
      top: calc(50% - 109px / 2 - 10px);
      background: #ffffff;
      border: 1px solid #787878;
      box-sizing: border-box;
      border-radius: 8px;
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
      margin: 12px 0px;

      :focus {
        border: 1px solid #787878;
        border-radius: 8px;
      }
    }

    .upload-image {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 7px 16px 8px;

      position: static;
      width: 360px;
      height: 39px;
      left: calc(50% - 360px / 2);
      top: calc(50% - 39px / 2 + 76px);
      background: #ffffff;
      border: 1px solid #5fac45;
      box-sizing: border-box;
      border-radius: 8px;
      flex: none;
      order: 2;
      align-self: stretch;
      flex-grow: 0;
      margin: 12px 0px;

      span {
        position: static;
        height: 19px;
        left: 40.97%;
        right: 32.08%;
        top: calc(50% - 19px / 2 - 0.5px);
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 25px;
        text-align: center;
        color: #4d4d4d;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0px 8px;
      }

      i.camera-ico {
        background-image: url('../../assets/images/others/camera.png');
        background-position: center;
        background-repeat: no-repeat;
        width: 25px;
        height: 24px;
      }
    }

    .btn-toolbar {
      margin-top: 24px;

      button {
        display: inline-block;
        width: 172px;
        height: 34px;
        margin-right: 15px;
        text-align: center;

        &.cancel {
          background: #ffffff;
          border: 1px solid #5fac45;
          box-sizing: border-box;
          border-radius: 8px;
        }

        &.submit.disabled {
          background: #efefef;
          border-radius: 8px;
          border: none;

          span {
            color: #b5b5b5;
          }
        }

        &.submit.available {
          background: #5fac45;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.160784);
          border-radius: 8px;
          border: none;

          span {
            color: #ffffff;
          }
        }
      }
    }
  }
}

#share-link {
  width: 346px !important;
  max-width: 350px !important;
  margin: auto;

  .link {
    text-align: center;

    > input {
      background: #ffffff;
      border: none;
      width: 220px;
      margin-right: 10px;

      &:focus {
        border: none;
        outline: none;
      }
    }
  }

  .close-share-modal {
    margin: auto !important;
    margin-bottom: 15px !important;
  }
  .copy-link {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #21a2dc;
    display: block;
    margin: 15px;
  }
}

#download-modal {
  width: 346px !important;
  margin: auto;

  .download-btns {
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      width: 145px;
      margin: 20px 10px;

      &.close-btn {
        margin-right: 5px;
      }

      &.download-btn {
        background: #5fac45;
        border-radius: 8px;
        color: #fff;
        border: none;
        margin-left: 5px;
      }
    }
  }
}

#exit-navigation, #staff-logout {
  width: 332px !important;
  margin: auto;

  .modal-close {
    display: flex;
    justify-content: flex-end;
    padding: 10px;

    i {
      background-image: url('../../assets/images/others/x.png');
      background-repeat: no-repeat;
      background-position: center;
      width: 14px;
      height: 15px;
      cursor: pointer;
    }
  }

  h4 {
    font-family: Open Sans;
    font-style: normal !important;
    font-weight: bolder !important;
    font-size: 14px !important;
    line-height: 16px !important;
    text-align: center;
    color: #000000;
    width: 225px;
    margin: auto;
    margin-top: 0 !important;
  }

  .modal-width {
    max-width: 470px !important;
  }

  .exit-navigation-btns {
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      width: 147px;
      padding: 0;
      margin: 12px 9px;
      border-radius: 8px;
      border: none;
      color: #fff;

      &.continue-btn {
        background: #5fac45;
      }

      &.exit-navigation-btn, &.logout {
        background: #d52744;
      }

      &.finish-back-btn {
        border: 1px solid #c8c8c8;
      }
    }
  }
}

#thank-you,
#share-link,
#download-modal,
#exit-navigation,
#staff-logout {
  width: 270px;

  h4 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #000000;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: 30px;
  }

  p {
    width: 246px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px !important;
    line-height: 25px;
    text-align: center;
    color: #000000;
    margin-top: 15px;
    margin-left: 11px;
  }

  .reference-code,
  .link {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 10px;
    background: #ffffff;
    border: 1px solid #c8c8c8;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 16px 35px;
    justify-content: center;

    .fa-copy {
      font-size: 16px;
      cursor: pointer;
    }
  }

  .modal-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 38px 8px;
    position: static;
    width: 240px;
    height: 34px;
    left: 0px;
    top: 0px;
    background: #ffffff;
    border: 1px solid #5fac45;
    box-sizing: border-box;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 1;
    margin: 20px 16px;

    &.continue-btn,
    &.exit-navigation-btn,
    &.logout,
    &.download-btn {
      span {
        color: #fff;
      }
    }

    span {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: #4d4d4d;
    }
  }

  .close-btn {
    background: #ffffff;
    border: 1px solid #5fac45;
  }
}

.empty-search-intro {
  text-align: center;
  padding-top: 20px;
  width: 65%;
  margin: auto;

  i.fa-search {
    font-size: 24px;
  }

  p:nth-child(2) {
    font-size: 16px;
  }
}

.modal-width {
  max-width: 420px;
}

#welcome-ui-window {
  .scrollbar-container {
    height: 98%;
  }
}

#find-ui-window {
  .scrollbar-container {
    height: 99%;
  }
}

#favorite-ui-window,
#welcome-ui-window {
  .favorites-ico {
    background-image: url('../../assets/images/others/star.svg');
    width: 36px;
    height: 36px;
    background-repeat: no-repeat;
    background-position: center;
  }
}

#favorite-ui-window,
#welcome-ui-window,
#find-ui-window {
  .show-all-favorites {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #4d4d4d;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    width: 94%;

    i {
      margin-top: 5px;
      font-size: 20px;
    }
  }

  .main-menu-container .menu-btns-container {
    flex-direction: row !important;
    justify-content: start;
    padding: 10px 16px 0;
  }

  .space {
    div h5 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: #282828;
      margin: 0;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }

  .welcome-header {
    h4 {
      margin-bottom: 0 !important;
      margin-top: 15px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 17px;
      color: #000000;

      @include media('<=phone') {
        margin-top: 0;
      }
    }
  }

  .spreo-poi-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;

    & > div {
      display: flex;
      flex-direction: column;
      left: 10px;
      position: relative;
    }

    .recent-info {
      flex-direction: row !important;
      width: 100%;
      left: 0 !important;

      .recent-info-text {
        width: 85%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    span {
      top: 0 !important;
    }
  }

  .button-leave {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  .button-leave.button-leave-active {
    opacity: 0;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    transition: all 400ms ease-in-out;
  }
}

.categories, .navigate {
  .recent-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    &.no-info-block {
      margin-bottom: 0;
    }
  }
}

.category-poi-list {
  .scrollbar-container {
    height: 95% !important;
  }
}

.spreo-ui-window.ui-window {
  overflow-y: hidden;
  height: 100%;

  &.mt-10 {
    margin-top: 60px;
  }

  .paragraph-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .favorites-ico {
      background-image: url('../../assets/images/others/empty_star.svg') !important;
    }

    .favorites-paragraph {
      padding: 10px;
      margin-bottom: 0;
      font-size: 16px;
      line-height: 22px;
      color: #000;
      margin-top: 0;
      text-align: center;
      border: none;
    }
  }

  .back-btn {
    padding-left: 0;
  }

  .collapsing {
    transition: none !important;
  }

  .filters-container,
  .filtered-block {
    .accordion {
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);

      .floor-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
      }
    }
    .accordion:nth-child(2) {
      border-bottom: none;
    }

    .card-header {
      background: transparent !important;
      border-bottom: none !important;

      .filter-left {
        display: flex;
      }

      div:first-child {
        display: flex;
        padding: 5px;
        justify-content: space-between;
        cursor: pointer;

        span {
          margin-left: 10px;
        }
      }
    }

    .selected-filter {
      background: #4d4d4d !important;
      color: white !important;

      i {
        background-image: url('../../assets/images/others/delete.svg');
        width: 24px;
        height: 24px;
        margin-left: 5px;
        cursor: pointer;
      }
    }

    .filter-header {
      width: 296px;
      height: 40px !important;
      border-radius: 6px !important;
      margin: 0 35px !important;

      .bars-btn {
        padding-top: 6px !important;
        height: 40px;
      }
      .srch-form {
        padding: 10px 0;
      }
      .spreo-search {
        padding-top: 0 !important;
      }
    }

    .ml-20 {
      margin-left: 20px !important;
    }

    .ml-0 {
      margin-left: 0 !important;
    }

    .filter-btns {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
    }

    .filter-btn {
      display: inline-block;
      width: 152px;
      height: 34px;
      margin: 15px;

      &.clear {
        background: #ffffff;
        border: 1px solid #5fac45;
        box-sizing: border-box;
        border-radius: 8px;
      }

      &.filter {
        background: #5fac45;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.160784);
        border-radius: 8px;
        border: none;

        span {
          color: #ffffff;
        }
      }

      span {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
      }
    }

    button.filter-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 6px 12px;
      width: fit-content;
      height: 34px;
      background: #ffffff;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.160784);
      border-radius: 20px;
      margin: 12px 0px;
      padding: 10px !important;

      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #4d4d4d;
      cursor: pointer;
      border: none;
      margin-left: 35px;
    }
    .filter-buildings-ico {
      background-image: url('../../assets/images/others/building.svg');
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: center;
    }
    .filter-floors-ico {
      background-image: url('../../assets/images/others/levels.svg');
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .filters-header {
    margin-bottom: 0 !important;
  }

  .window-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    h4 {
      margin-left: 8px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #000000;
    }

    .window-header-left {
      width: 80%;

      .window-poi-title {
        margin-top: 5px;
      }
    }
  }

  .spreo-poi-btn {
    span {
      position: relative;
      top: 8px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #000000;

      &.no-info-block {
        top: 0;
      }
    }
  }

  .btn-block {
    width: 97%;
  }
  .spreo-poi-btn {
    padding-right: 5px;
  }

  .poi-description {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #000000 !important;
  }

  .poi-info {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 16px !important;
    display: flex !important;
    align-items: center !important;
    color: #787878 !important;
  }

  .building-poi-btn,
  .category-poi-btn {
    padding-bottom: 20px;
  }
  .scrollbar-container {
    height: 90%;
  }
  .no-poi-building,
  .no-poi-category {
    margin-left: 20px;
  }
}

.srch-close-btn {
  padding: 0;
  margin: 0;
  width: 35px;
  height: 24px;
  padding-right: 30px;
}

#navigate {
  padding-top: 10px;

  &.active {
    padding-right: 0;
    padding-left: 0;
  }

  #find-header {
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px !important;
  }

  #find-ui-window {
    width: 390px;
    padding-left: 15px;
    padding-right: 10px;
    left: 0 !important;
  }
}

#navigate-ui-window {
  left: 0px;
  width: 390px;

  .close-navigate {
    padding-top: 5px;
  }

  #nav-window {
    .nav-instruction .scrollbar-container,
    .nav-help .in-route {
      padding-left: 10px;
    }
  }

  .window-header,
  .navigate-fields,
  .scrollbar-container {
    padding-left: 20px;
    padding-right: 10px;
  }

  .instruction-cont {
    @include media('>=phone') {
      padding-left: 20px;
    }
  }

  & > .scrollbar-container {
    height: 75%;

    .scrollbar-container {
      height: 100% !important;
      padding-left: 0;
    }
  }

  .navigate-content {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 98%;

    .spreo-poi-btn {
      padding-right: 0px;
      height: 54px;
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      span {
        top: 0;
      }
    }

    .btn-block {
      width: 100%;
    }

    .navigate-item {
      border: none;
      border-bottom: 1px solid #ccc;
      background: transparent;
      height: 54px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &:hover {
        background: #e6e6e6;
      }

      .fa-angle-right {
        line-height: 1.8;
      }

      div {
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
          margin-left: 12px;
        }
      }

      i {
        width: 24px;
        height: 24px;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    .nav-search {
      i {
        background-image: url('../../assets/images/others/search.svg');
      }
    }
    .nav-parking {
      i {
        background-image: url('../../assets/images/others/parking.svg');
      }
    }
    .nav-qr-code {
      i {
        background-image: url('../../assets/images/others/qr-code.svg');
      }
    }
    .nav-favorites {
      div:first-child {
        i {
          background-image: url('../../assets/images/others/favorites_checked.svg');
        }
      }
    }
    .nav-recent {
      div:first-child {
        i {
          background-image: url('../../assets/images/others/history.svg');
        }
      }
    }
  }
  .navigate-fields {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 20px;

    .swap-nav {
      display: flex;
      i {
        background-image: url('../../assets/images/others/swap.svg');
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
      }
    }

    .nav-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 16px;

      .nav-remove i {
        font-size: 20px;
        cursor: pointer;
      }
    }

    .nav-from,
    .nav-to {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      margin-bottom: 8px;

      @include media('<=phone') {
        margin-bottom: 10px;
      }

      > i {
        width: 15px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: 14px;
        background-position: center;

        @include media('<=phone') {
          width: 13px;
        }
      }

      .cut-input {
        width: 250px;
      }

      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 8px;
        position: static;
        width: 308px;
        height: 36px;
        left: 36px;
        top: 0px;
        background: #ffffff;
        border: 1px solid #5fac45;
        box-sizing: border-box;
        box-shadow: 0px 0px 8.85px rgba(0, 0, 0, 0.160784);
        border-radius: 8px;
        flex: none;
        order: 1;
        margin: 0px 12px;

        span {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          color: #4d4d4d;
          white-space: nowrap;
          width: 88%;
          overflow-x: hidden;

          @include media('<=phone') {
            width: 225px;
          }
        }

        i {
          text-align: right;
        }
      }
    }

    .nav-from {
      > i {
        background-image: url('../../assets/images/map-icons/navigation_start_point_no_shadow.svg');
      }
    }
    .nav-to {
      > i {
        background-image: url('../../assets/images/map-icons/navigation_end_point_no_shadow.svg');
      }
    }
  }
}

@media (max-width: 1025px) {
  .sidebar-loading {
    width: 100% !important;
  }
}

.sidebar-loading {
  position: absolute;
  width: 390px;
  bottom: 0;
  height: 100%;
  z-index: 500;
  opacity: 0.6;
  background: white;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

#go-now-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 3.89%;
  right: 3.89%;
  top: 3.55%;
  bottom: 90.13%;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  height: 48px;
  z-index: 301;

  .back-btn {
    height: 47px;
  }

  .window-header-left {
    display: flex;
    align-items: center;

    .window-poi-title {
      margin-left: 15px;
      margin-top: 15px;
    }
  }
}

#third-screen-searchbar {
  height: 50px;
  margin: 10px 26px;
  top: 20px;
  border-radius: 10px;
  left: 0;
  right: 0;
  width: auto;
  position: absolute;
  z-index: 302;

  button.filter-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    width: fit-content;
    height: 34px;
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.160784);
    border-radius: 20px;
    margin: 12px 0px;
    padding: 10px !important;

    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #4d4d4d;
    cursor: pointer;
    border: none;
    margin-left: 35px;
  }

  .selected-filter {
    background: #4d4d4d !important;
    color: white !important;

    i {
      background-image: url('../../assets/images/others/delete.svg');
      width: 24px;
      height: 24px;
      margin-left: 5px;
      cursor: pointer;
    }
  }

  .srch-form {
    padding-left: 12px !important;
  }
}

.third-screen.route {
  top: 68vh !important;

  .window-header {
    display: none;
  }

  #nav-window {
    margin-top: 0;
  }
}

.instruction-cont {
  .go-now-btn {
    color: #fff;
    background: #5fac45;
    border-radius: 8px;
    width: 340px;
    height: 35px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 6px;

    @include media('<=phone') {
      width: -webkit-fill-available;
      margin: 8px;
    }

    div:first-child {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }

    div:nth-child(2) {
      display: flex;
      align-items: center;
      top: 8px;
      position: relative;
      right: 10px;

      .number-time {
        margin-right: 5px;
      }
    }

    .instruction-navigate-ico {
      display: inline-block;
      width: 15px;
      margin: 7px 0;
      height: 15px;
      background-size: 15px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url(../../assets/images/navigate-ico.svg);
    }

    span {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.image-ico-right {
  order: 3;
  align-self: center;
  flex-basis: 37px;
}

.info-right {
  order: 2;
  display: flex !important;
  flex-direction: column !important;
  padding-right: 15px !important;
  align-items: flex-end !important;

  .poi-description,
  .poi-info,
  .matched-keywords {
    direction: rtl;
  }

  .right-dir {
    margin-right: 0 !important;
  }
}

.angle-left {
  order: 1;
}
.info-right-button {
  justify-content: space-between !important;
  padding-right: 20px !important;

  div:first-child {
    order: 2;

    .poi-info {
      justify-content: flex-end;
    }
  }
}
.pr-0 {
  padding-right: 0 !important;
}

.nav-item-right {
  justify-content: flex-end !important;

  div:first-child {
    direction: rtl;

    span {
      margin-right: 10px;
    }
  }
}

.item-right {
  div:first-child {
    order: 2;
    direction: rtl;

    span {
      margin-right: 10px;
    }
  }

  div:last-child {
    order: 1;

    i {
      margin-top: 12px;
    }
  }

  span:first-child {
    order: 2;
    margin-right: 10px;
  }

  i:last-child {
    order: 1;
    margin-left: -5px;
  }
}

.recent-header,
.favorite-header {
  margin-top: 16px;
  margin-bottom: 16px;
}

.results-header {
  margin-top: 8px;
  margin-bottom: 8px;
}

.recent-header,
.results-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  a {
    color: black;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;

    &:hover {
      color: black;
    }
  }
}

/* Mobile declaration */
@media (max-width: 1025px) {
  .sidebar-tabs {
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }

  .sidebar-right .sidebar-tabs,
  .sidebar-left .sidebar-tabs {
    bottom: 0;
  }

  .sidebar-tabs,
  .sidebar-tabs > ul {
    display: inline;
    position: absolute;
    height: 40px;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
    border: 0;
  }

  .sidebar-tabs > li,
  .sidebar-tabs > ul > li {
    display: inline;
    width: 20px;
    height: 100%;
    color: #666;
    font-size: 12pt;
    align-content: center;
    text-align: center;
    overflow: hidden;
    transition: all 80ms;
  }

  .sidebar-tabs > li > a,
  .sidebar-tabs > ul > li > a {
    display: inline;
    width: 20px;
    margin-right: 10px;
    margin-left: 15px;
    height: 100%;
    line-height: 40px;
    color: inherit;
    text-decoration: none;
    text-align: center;
  }

  .sidebar-tabs > ul + ul {
    right: 0;
  }

  .spreo-bars {
    background-color: #fff;
    color: #000;
  }
  .bars-btn {
    height: 50px;
    overflow: hidden;
    transition: all 80ms;
    padding-top: 13px;
    line-height: 25px;
    padding-left: 20px;
    padding-right: 19px;
    margin-right: 10px;
    text-align: center;
    border-right: 1px solid #e5e5e5;
  }
  .sidebar-header {
    margin: -10px -10px 0;
    height: 50px;
    line-height: 35px;
    font-size: 12.5pt;
    color: #fff;
    background-color: #fff;
    border-radius: 10px;

    #bars-home {
      padding-right: 0;
      border-right: 0;
      .fa-search {
        color: #4d4d4d;
      }
    }
    #searchBar #home-input {
      padding-left: 0;
    }
  }

  .sidebar {
    border: 0;
    box-shadow: rgba(0, 0, 0, 0.298039) 0 1px 4px -1px;
  }

  .sidebar-header {
    margin-left: 10px !important;

    #bars-find {
      color: #000 !important;
    }
  }

  .sidebar {
    position: fixed;
    right: 0;
    left: 0;
    height: 100%;
    overflow: hidden;
    z-index: 200;

    #home {
      padding-left: 10px !important;
      padding-right: 0px;
    }

    .sidebar-pane {
      &.active {
        height: 100%;
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }

  .third-screen {
    &.top66 {
      top: 66vh !important;
    }

    .sidebar-pane {
      &.active {
        padding-top: 16px;
      }
    }

    .scrollbar-container {
      height: 52% !important;
    }

    #favorite-ui-window, #building-ui-window {
      .scrollbar-container {
        height: 25% !important;
      }
    }
  }

  #campus-map,
  #report_an_issue {
    width: 95% !important;
  }

  #campus-map {
    .campus-header {
      width: 95% !important;
    }
    .form_radio {
      width: 100% !important;
    }
  }

  #qr_scanner {
    background: black;
    color: white;

    .modal-content {
      background: inherit;
      padding: 10px;

      .back-btn {
        height: 26px;
      }

      .back-btn,
      h4 {
        background: inherit;
        color: white;
        font-size: 26px !important;

        .fa-arrow-left {
          color: white !important;
        }
      }
    }

    .window-content {
      p {
        color: #c8c8c8;
        font-size: 15px;
        padding: 10px;
      }
    }
  }

  #report_an_issue,
  #qr_scanner {
    width: 100vw !important;
    max-width: none;
    min-height: 100% !important;
    height: 100%;
    margin: 0;

    @media (orientation: landscape) {
      height: unset;
    }

    .modal-width {
      max-width: 100%;
    }

    .modal-content {
      min-height: 100% !important;
    }

    textarea,
    .upload-image {
      width: 95% !important;
    }

    .report-issue-form {
      margin-left: 18px;
      display: flex;
      flex-direction: column;
      height: auto;
      justify-content: space-between;

      .report-issue-buttons {
        position: absolute;
        bottom: 20px;
        width: 95%;

        @media (orientation: landscape) {
          position: relative;
        }
      }
    }

    .btn-toolbar {
      display: flex;
      order: 4;
      flex-grow: 10;
      flex-wrap: wrap-reverse;
      justify-content: center;

      button {
        width: auto !important;
        flex-grow: 1;
      }
    }

    .window-header {
      margin-top: 15px;

      .back-btn {
        width: 50px;
      }
    }

    .campus-modal h4 {
      margin-top: -3px;
    }
  }

  #thank-you {
    margin: auto;
  }

  #home-ui-window {
    overflow-y: scroll;
  }

  .sidebar.collapsed,
  .sidebar.home.collapsed {
    left: 0;
    right: 0;
    margin: 0px 15px;
    height: 50px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .sidebar.navigation {
    height: 160px;
    margin: 13px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.161) 0 3px 6px 0px;

    .back-btn {
      display: none;
    }

    .srch-close-btn span {
      font-size: 34px;
      left: 14px;
    }

    .navigate-fields {
      padding-left: 0px !important;
      margin-bottom: 7px !important;
    }

    .nav-container {
      width: 100%;
      border-radius: 7px;
      box-shadow: rgba(0, 0, 0, 0.161) 0px 5px 6px -2px;
      padding-bottom: 14px;
    }

    .nav-from,
    .nav-to {
      width: 98%;
    }

    .input-from,
    .input-to {
      margin: 0px 0 0 12px !important;
    }

    .navigate-content {
      margin-top: 0 !important;
    }

    .nav-remove {
      margin: 0px 7px 0 12px;
    }
    .swap-nav {
      position: absolute;
      right: 7px;
      top: 82px;
    }
    .inputs-container {
      width: 91%;
    }

    // route mode styles
    .in-route {
      height: 21px;
      display: flex;
      align-items: center;
      border-top: none;
      background: #fff;
      z-index: 600;
      margin-right: 26px;
    }

    #handicapped-container {
      right: 13px;
    }

    #route-buttons {
      left: 16px;
    }
  }

  .sidebar.navigation.route {
    height: 228px;

    .nav-container {
      box-shadow: none;
      padding-bottom: 0;
    }

    .navigate-fields {
      margin-bottom: 4px !important;
    }
  }

  .sidebar.navigation.dropdown {
    overflow: inherit;

    .back-btn {
      display: block;
      height: auto;

      .back-btn-ico {
        right: 23px;
        background-size: 17px;
        top: 14px;
      }
    }

    .sidebar-content {
      height: auto;
      max-height: 96vh;
      border-radius: 4px;

      .scrollbar-container {
        height: auto !important;
        max-height: 70vh;
        overflow: scroll !important;
      }
    }
  }

  #sidebar.third-screen{
    top: 60vh;
    border-radius: 16px;

    .slider-rectangle-touch {
      width: 100%;
      height: 70px;
      position: absolute;

      .slider-rectangle {
        background-color: #c4c4c4;
        width: 24px;
        height: 4px;
        margin: 8px auto -8px;
        border-radius: 20px;
      }
    }
  }

  #sidebar.route {
    .slider-rectangle-touch {
      width: 100%;
      height: 70px;
      position: absolute;

      .slider-rectangle {
        background-color: #c4c4c4;
        width: 24px;
        height: 4px;
        margin: 8px auto -8px;
        border-radius: 20px;
      }
    }
  }

  .spreo-ui-window {
    width: 98%;

    &.ui-window {
      .scrollbar-container {
        height: 90%;
      }
      .btn-block {
        width: 97% !important;
      }
      .mt-10 {
        margin-top: 65px;
      }

      .filter-header {
        width: 85% !important;

        a {
          color: black;
        }
      }
    }
  }

  .info {
    .spreo-ui-window {
      width: 100%;
    }
  }

  #navigate {
    .sidebar-header {
      margin-right: 16px;
    }

    .navigate-search-intro {
      margin-top: 20px;
      width: 250px;
      left: 50%;
      position: relative;
      transform: translate(-55%, 10px);
    }

    #find-ui-window {
      width: 100% !important;
      height: 85% !important;
    }
  }

  #navigate-ui-window {
    width: 100% !important;
    overflow-x: hidden;

    .instruction-cont {
      width: 98%;
      padding-left: 15px;
    }

    .navigate-fields {
      justify-content: flex-start !important;
      padding-left: 13px;
      padding-right: 0;

      .nav-to div,
      .nav-from div {
        width: 90% !important;
      }
    }

    .scrollbar-container {
      padding-right: 10px !important;
      height: 75% !important;
    }
  }

  .back-btn,
  .filter-btn {
    padding-top: 0;
  }

  .ps__rail-y {
    visibility: hidden;
  }

  .window-header {
    .window-header-left {
      .back-btn,
      .filter-btn {
        height: 45px;
      }

      .window-poi-title {
        padding-top: 10px;
      }
    }
  }

  #qrCode {
    margin-right: 8px;

    .qr-code-ico {
      background-image: url('../../assets/images/others/qr-code.svg');
      background-position: center;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      display: inline-block;
      position: relative;
      bottom: 2px;
    }
  }

  .filter-btn-ico {
    position: relative;
    bottom: 2px;
  }

  .back-btn {
    left: 8px;
    position: relative;
  }

  .filter-btns {
    position: relative;
    bottom: 0;
  }

  .sidebar:not(.third-screen) .find:not(.poi-card) #find-ui-window {
    .scrollbar-container {
      height: 85% !important;
    }
  }

  .poi-card {
    padding: 0 !important;

    #find-ui-window {
      width: 100% !important;
      height: 100% !important;
      margin: 0 !important;

      .poi-back-btn {
        position: absolute;
        width: 40px;
        height: 40px;
        left: 16px;
        top: 17px;
        background: #ffffff;
        box-shadow: 0 0 8.85px rgba(0, 0, 0, 0.160784);
        border-radius: 22px;
        z-index: 500;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          background-image: url('../../assets/images/others/arrow_back.svg');
          background-repeat: no-repeat;
          background-position: center;
          width: 24px;
          height: 24px;
        }
      }

      .poi-gallery img {
        margin: 0 !important;
        max-width: 100vw !important;
        width: 100%;
      }

      .space {
        .poi-desc-header,
        .description,
        .main-menu-container {
          padding: 5px 16px 0 !important;
        }

        .pl-75 {
          padding-left: 75px !important;
        }

        .pt-10 {
          padding-top: 10px !important;
        }

        .poi-title {
          color: #000000;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
        }

        .poi-separator {
          width: 90%;
          height: 1px;
          background: #d5d5d5;
          left: 20px;
          position: relative;
          margin-top: 16px;
        }
      }
    }
  }

  #poiDesc {
    .main-menu-container {
      margin-bottom: 15px;
    }
  }

  #parking-ui-window {
    .window-header {
      margin-bottom: 0 !important;
    }

    .window-content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 30%;

      .parking-top {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      i {
        background-repeat: no-repeat;
        background-position: center;
        width: 36px;
        height: 36px;
      }

      .my-parking-ico {
        background-image: url('../../assets/images/others/parking_36.svg');
      }

      .no-parking-ico {
        background-image: url('../../assets/images/others/no_parking_36.svg');
      }

      p {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        text-align: center;

        span,
        a {
          padding: 2px;
        }

        a {
          color: #337ab7;
        }
      }
    }
  }

  #info-ui-window {
    .you-are-here-info {
      &.window-header {
        margin-bottom: 0 !important;

        .window-header-left {
          flex-direction: column;

          .help-block {
            display: flex;
            margin-top: -5px;
            margin-bottom: 15px;
            color: #737373;
            margin-left: 8px;
          }
        }

        .srch-close-btn {
          height: 80px;
        }
      }
    }

    .window-header {
      margin-bottom: 0 !important;

      .window-header-left {
        flex-direction: row;
      }

      .srch-close-btn {
        display: flex;
        align-items: flex-start;
        height: 50px;
        order: 1;
        justify-content: flex-end;
        padding-right: 8px;

        span {
          height: auto;
          position: initial;
        }
      }
    }

    .window-content {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      margin-top: 10px;

      .poi-image {
        width: 50%;

        img {
          max-width: 100%;
        }
      }
    }
  }

  #info-ui-window,
  #parking-ui-window {
    .window-content {
      .poi-info-buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        button {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }

      .parking-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
      }

      .poi-info-buttons,
      .parking-buttons {
        button {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          padding: 7px 16px 8px;
          width: 149px;
          height: 39px;
          box-sizing: border-box;
          border-radius: 8px;

          &.all-btn {
            width: 300px;
          }

          &.poi-info-btn,
          &.parking-delete-btn,
          &.all-btn {
            background: #ffffff;
            border: 1px solid #5fac45;
            color: #000;

            i {
              background-image: url('../../assets/images/others/information.svg');
            }
          }

          &.poi-go-from-btn,
          &.go-to-parking-btn {
            background: #5fac45;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.160784);
            border: none;
            color: #fff;

            i {
              background-image: url('../../assets/images/others/navigation.svg');
            }
          }

          span {
            font-family: Open Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
          }

          i {
            background-position: center;
            background-repeat: no-repeat;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  .info,
  .parking {
    padding-top: 16px !important;
    top: 55vh !important;
  }

  #delete-parking-modal {
    width: 346px !important;
    margin: auto;

    .btns-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      button {
        width: 153px;
        height: 34px;
        margin: 10px 8px;
        border-radius: 8px;

        &.close-btn {
          border: 1px solid #5fac45;
          background: #fff;
        }

        &.yes-btn {
          background: #5fac45;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.160784);
          color: #fff;
          border: none;
        }
      }
    }
  }

  .qr-reader {
    div {
      box-shadow: none;
      border: none !important;
      background-image: url('../../assets/images/tab-icons/scan-target.svg') !important;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .qr-detected {
    div {
      box-shadow: #7a9a46 0 0 0 5px inset !important;
    }
  }

  @-webkit-keyframes scanner {
    0% {
      bottom: 90%;
    }
    50% {
      bottom: 10%;
    }
    100% {
      bottom: 90%;
    }
  }

  @-moz-keyframes scanner {
    0% {
      bottom: 90%;
    }
    50% {
      bottom: 10%;
    }
    100% {
      bottom: 90%;
    }
  }

  @-o-keyframes scanner {
    0% {
      bottom: 90%;
    }
    50% {
      bottom: 10%;
    }
    100% {
      bottom: 90%;
    }
  }

  @keyframes scanner {
    0% {
      bottom: 90%;
    }
    50% {
      bottom: 10%;
    }
    100% {
      bottom: 90%;
    }
  }

  .custom-scanner {
    width: 270px;
    height: 2px;
    background: #4caf50;
    position: absolute;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    transition: all 200ms linear;
    -webkit-animation: scanner 3s infinite linear;
    -moz-animation: scanner 3s infinite linear;
    -o-animation: scanner 3s infinite linear;
    animation: scanner 3s infinite linear;
    box-shadow: 0 1px 0 0 #000;
    display: block;
    left: -10px;
    right: 0;
    margin: auto;
  }

  .show-in-map {
    z-index: 1000;
  }

  .poi-gallery img {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  #poiDesc.qr-scan-data {
    .poi-gallery {
      text-align: center;
      border-radius: 8px;

      img {
        text-align: center;
        display: inline-block;
        max-width: 98%;
        line-height: 1.42857143;
        background-color: #fff;
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
        margin: 0;
        margin-left: 5px;
        border-radius: 8px;
        max-width: 100%;
      }
    }

    .poi-desc-header {
      #you-are-at {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #000000;
        margin: 0;
        padding-bottom: 3px;
      }

      #poi-title {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #5fac45;
        margin: 0 !important;
        padding-bottom: 3px;

        div {
          max-width: 100%;
        }
      }

      .help-block {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 12px !important;
        line-height: 16px !important;
        text-align: center;
        color: #666666;
        margin: 0 !important;
      }
    }

    #poi-qr-buttons {
      margin-top: 27px !important;

      .show-map-btn {
        background-image: url('../../assets/images/tab-icons/button_icon_map.svg');
      }

      #navBtnInfo {
        #qr-navigate-btn-info {
          border: 2px solid #4d4d4d;
          box-sizing: border-box;
          border-radius: 30px;
          padding: 10px;
          background-image: url('../../assets/images/tab-icons/information.svg');
        }
      }
    }
  }

  .filters-container {
    display: flex;
    min-height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media (max-width: 360px) {
  #info-ui-window .window-content .poi-image img {
    width: 90%;
  }

  .navigate-fields .poi-name {
    width: 160px !important;
  }
}

@media (orientation: landscape) and (max-width: 900px) {
  .menu-btn {
    zoom: 0.8;
  }

  .gmnoprint {
    zoom: 0.9;
  }

  .third-screen.top66 {
    top: 25vh !important;
  }

  .third-screen.parking {
    top: 35vh !important;
  }

  .category-btn {
    zoom: 0.9;
  }

  .third-screen.route {
    top: 40vh !important;
  }
}

@media (max-height: 800px) {
  .third-screen {
    &.route,
    &.top66 {
      top: 60vh !important;
    }
    &.only-two {
      top: calc(100% - 220px) !important;
    }
  }
}

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .third-screen {
    &.route,
    &.top66 {
      top: 60vh !important;
    }
    &.only-two {
      top: 63vh !important;
    }
  }
}

@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  .third-screen {
    &.route,
    &.top66 {
      top: 60vh !important;
    }
    &.only-two {
      top: 63vh !important;
    }
  }
}

@media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
  .third-screen {
    &.route,
    &.top66 {
      top: 60vh !important;
    }
    &.only-two {
      top: 63vh !important;
    }
  }
}

@media (orientation: landscape) and (max-width: 900px) {
  #navigate-ui-window .navigate-fields .nav-from div span,
  #navigate-ui-window .navigate-fields .nav-to div span {
    width: 100% !important;
  }

  #find {
    .spreo-ui-window {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  #sidebar {
    &.third-screen {
      top: 65vh;
      right: 15px;
      left: 15px;
    }

    &.info-pane {
      top: 40vh !important;
      right: 0;
      left: 0;

      #info {
        .window-content {
          justify-content: space-between;
          align-items: start;

          .poi-image {
            width: 58%;
          }
          .poi-info-buttons {
            button {
              width: 250px;
              margin-top: 5px;
              margin-bottom: 5px;
              align-items: center;
              justify-content: center;
            }
          }
        }

      }
    }

    &.landscape {
      left: 15px;
      right: 15px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;

      .slider-rectangle-touch {
        width: 100%;
        height: 70px;
        position: absolute;

        .slider-rectangle {
          background-color: #c4c4c4;
          width: 24px;
          height: 4px;
          margin: 8px auto -8px;
          border-radius: 20px;
        }
      }
    }

    &.route.third-screen {
      top: 25vh !important;

      #nav-window {
        .nav-instruction {
          .inst-buttons {
            width: 96%;
          }
        }
      }
    }

    &.navigation.dropdown {
      .sidebar-content {
        .scrollbar-container {
          max-height: 55vh;
        }
      }
    }

    #favorites, #buildings, #categories {
      .spreo-ui-window.ui-window {
        .scrollbar-container {
          height: 70%;
        }
      }
    }

    #categories {
      .category-poi-list {
        .scrollbar-container {
          height: 84% !important;
        }
      }
    }
  }

  .spreo-ui-window.ui-window .window-header {
    margin-bottom: 65px;
  }

  #navigate-ui-window.spreo-ui-window.ui-window .window-header {
    margin-bottom: 20px;
  }

  .qr-scanner-modal {
    overflow-y: hidden !important;
  }

  #qr_scanner {
    .modal-content {
      padding: 0 !important;
    }

    .window-header {
      position: absolute;
      z-index: 10;
    }

    .window-content {
      .qr-reader > section > div:first-child {
        height: 46% !important;
      }

      .qr-reader > section > video {
        height: 47% !important;
      }
    }

  }
}

@media (orientation: landscape) and (max-width: 780px) {
  #qr_scanner {
    .window-header {
      top: 25% !important;
    }

    .window-content {
      .qr-reader > section > div:first-child {
        height: 56.3% !important;
        top: 25.7% !important;
      }

      .qr-reader > section > video {
        height: 57% !important;
        top: 25% !important;
      }
    }
  }
}

.you-are-here {
  font-size: 18px;
  background: #5fac45;
  border: 4px solid #ffffff;
  border-radius: 20px;
  color: #ffffff;
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.160784));
  transform: translate(-50%, -142%);
  width: 300px;
  max-width: 195px !important;

  button {
    display: none !important;
  }

  .gm-style-iw-d {
    overflow: auto !important;
  }

  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 100%;
    width: 0;
    height: 0;
    border-style: solid;
  }

  &::after {
    top: 90%;
    left: 50%;
    border-color: #5fac45 transparent transparent transparent;
    border-width: 22px 13.5px 0 13.5px;
    transform: translateX(-50%);
  }

  &::before {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-color: #ffffff transparent transparent transparent;
    border-width: 24px 15.5px 0 15.5px;
  }

  &+div.gm-style-iw-tc {
    display: none;
  }

  .marker-content {
    text-align: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    line-height: 25px;

    &.you-are-here-content {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin: 3px;

      div:first-child {
        width: 95%;
      }

      #you_are_here_link {
        cursor: pointer;
      }
    }

    h4 {
      font-size: 18px;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 0;
    }

    span {
      display: inline-block;
      font-size: 16px;
    }
  }
}

@import 'src/assets/styles/include-media.scss';

.lang-picker {
  width: 220px;
  height: 40px;

  @include media('<=phone') {
    width: 227px;
    margin-bottom: 10px;
  }

  .scrollbar-container {
    height: 100% !important;
  }

  .__select {
    background: white;
    border-radius: 6px;
    box-shadow: 0 0 8.85px rgba(0, 0, 0, 0.16);

    @include media('<=phone') {
      border: 1px solid #c8c8c8;
      border-radius: 8px;
      box-shadow: none;
    }
  }

  .__select[data-state="active"] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .__select__title {
    height: 40px;
    border: 0;

    i {
      display: block;
      width: 32px;
      height: 32px;
      background-image: url("../../assets/images/tab-icons/earth-ico.svg");
      background-position: left;
      background-repeat: no-repeat;
    }

    span {
      margin-left: 7px;
      font-size: 16px;
      font-weight: 450;
    }
  }

  .__select__content {
    top: 40px;
    max-height: 200px;
    width: 100%;
    left: 0;
    border: 0;
    box-shadow: 0 5px 8.85px rgba(0, 0, 0, 0.16);
    border-radius: 0 0 6px 6px;

    .__select__label {
      border-bottom: 0;
    }

    span:last-of-type .__select__label {
      border-radius: 0 0 6px 6px;
    }

    @include media('<=phone') {
      width: 227px;
      left: -1px;
      border-radius: 0 0 8px 8px;
    }
  }
}

@media (min-width: 1200px) {
  .lang-picker {
    position: fixed;
    top: 20px;
    right: 15px;
  }
}

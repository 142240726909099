@import '../../assets/styles/include-media.scss';

.categories-btn-container {
  position: fixed;
  top: 80px;
  left: 20px;
  height: 40px;
  z-index: 200;
  display: flex;

  @include media('<=phone') {
    left: 12px;
    z-index: 301;
  }

  & > .tns-outer {
    width: 320px;
    display: inline-block;
    vertical-align: middle;
    transition: width 500ms ease-in-out;
    border-radius: 0px;
    height: 40px;
  }

  .tns-ovh {
    height: 40px;
  }

  .tns-inner {
    margin-top: 3px !important;
    margin-left: 3px !important;
  }

  .tns-outer-expand {
    width: 50% !important;
    transition: width 500ms ease-in-out;
  }

  .margin-category {
    width: 8px !important;
  }

  .selected-category {
    background: #5a5a5a !important;
    cursor: default !important;

    span {
      color: #ffffff !important;
      display: flex !important;
    }

    :after {
      content: url('../../assets/images/others/delete.svg');
      position: relative;
      left: 15px;
      vertical-align: middle;
      cursor: pointer;
      margin-top: -1px;
    }
  }

  .category-btn {
    padding: 6px 12px;
    height: 34px;
    width: fit-content !important;
    width: -moz-fit-content !important;
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.160784);
    border-radius: 20px;
    text-align: center;
    margin-right: 8px;
    cursor: pointer;

    span {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #4d4d4d;
      white-space: nowrap;
      display: block;
      padding: 0 25px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }

  #tns1 > .tns-item {
    overflow: hidden;
    padding-right: 12px;
  }

  .expand-container {
    display: inline-block;
    position: relative;
    left: -30px;
    text-align: center;
    width: 60px;
    height: 25px;
    // background: linear-gradient(90deg, transparent 1%, #fff 30%, transparent);
    align-self: center;

    .expand-categories-btn {
      width: 30px;
      height: 30px;
      filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.160784));
      padding: 10px;
      background: white;
      border-radius: 20px;
      position: relative;
      left: 10px;
      top: 3px;
      cursor: pointer;
      outline: none;

      i {
        font-size: 17px;
        width: 20px;
        left: 0;
        position: relative;
      }
    }
  }
}

@media (max-width: 768px) {
  .categories-btn-container {
    left: 15px;

    & > .tns-outer {
      width: 295px;
    }
  }
}

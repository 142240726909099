/* Floating panel are the map floor buttons */
@import 'src/assets/styles/include-media.scss';

#floating-panel {
  z-index: 300;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 52px;
  min-height: 125px;

  .count-badge {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 5px;
    position: absolute;
    width: 22px;
    height: 22px;
    left: 10px;
    background: #ffffff;
    border: 2px solid #4d4d4d;
    border-radius: 36px;
    margin-top: 0px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #4d4d4d;

    &.selected-floor-badge {
      background: #4d4d4d;
      border: 2px solid #ffffff;
      color: white;
    }
  }

  .top-border-radius {
    .floor-btn {
      border-top-left-radius: 28px;
      border-top-right-radius: 28px;
    }
  }

  .bottom-border-radius {
    .floor-btn {
      border-bottom-left-radius: 28px;
      border-bottom-right-radius: 28px;
    }
  }

  .tns-ovh {
    height: 124px !important;
    -webkit-border-radius: 28px;
    -moz-border-radius: 28px;
    border-radius: 28px;
    -khtml-border-radius: 28px;
    box-shadow: 0px 0px 8.85px rgba(0, 0, 0, 0.16);
    transform: translateZ(0);
  }

  .page-arrow {
    font-size: 20px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 0px 8.85px rgba(0, 0, 0, 0.16);
  }

  .page-arrow .fas {
    position: relative;
    top: 3px;
    color: #4d4d4d;
  }

  .page-arrow .fas.fa-caret-down {
    top: -5px;
  }

  .tns-outer {
    width: 40px;
  }

  div.floor-btn {
    direction: ltr;
    text-align: center;
    color: #0f0f0f;
    font-family: 'Open Sans', Arial, sans-serif;
    font-weight: bold;
    font-size: 15px;
    background-color: #fff;
    padding: 10px 8px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 2px;
    box-shadow: 0 0 2px #00000029;
    margin-bottom: 2px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .page-arrow:focus {
    outline: none;
  }

  .filter-ico a {
    height: 59px;
    width: 59px;
    display: inline-block;
  }

  .current-floor {
    background-color: rgb(90, 90, 90) !important;
    color: rgb(255, 255, 255) !important;
  }

  .map-reload {
    background-image: url('../../assets/images/others/map-reload.svg');
    position: absolute;
    bottom: 320px;
    width: 40px;
    height: 40px;
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 28px;
    cursor: pointer;
    box-shadow: 0 0 8.85px rgba(0, 0, 0, 0.16);

    &:focus {
      outline: none;
    }
  }
}

@media (max-width: 1025px) {
  #floating-panel {
    z-index: 20;
    left: 18px;
    bottom: 67px !important;

    .map-reload {
      bottom: 160px;
      left: calc(100vw - 80px);
    }

    .floor-picker {
      bottom: 100px;
    }
  }

  .third-screen {
    #floating-panel {
      top: calc(50vh - 150px) !important;
    }
  }

  .spreo-map.third-screen-route {
    #floating-panel {
      bottom: 48% !important;
    }
  }
}

div[title][style*='width: 18px; height: 25px;'] {
  opacity: 1 !important;
}
.bridge-bubble-head {
  font-size: 12px;
}

.bridge-bubble-info {
  font-size: 12px;
  font-weight: 400;
}

.floor-btn-nav-mark {
  position: absolute;
  height: 25px;
  margin-top: -20px;
  left: 12px;
}

.floor-btn-nav-mark.yellow {
  left: -11px;
}

.find-me-btn:focus {
  outline: 0;
}

/** New design **/
.page-arrow {
  width: 36px;
  height: 36px;
  background: #fff;
  border-radius: 28px;
}
.next-butt {
  position: relative;
  top: 7px;
  left: 3px;
  box-shadow: 0 0 8.85px rgba(0, 0, 0, 0.16);
}
.prev-butt {
  position: relative;
  bottom: 7px;
  left: 3px;
}

@media (orientation: landscape) and (max-width: 900px) {
  #floating-panel {
    bottom: 25% !important;
    zoom: 0.8;
  }

  .third-screen {
    #floating-panel {
      bottom: 30% !important;
    }
  }

  .tab-overlay-desktop .overlay-spin.map {
    top: 38% !important;
    left: 43% !important;
  }
}

@media only screen
and (min-device-width: 414px)
and (max-device-width: 736px)
and (-webkit-min-device-pixel-ratio: 3) {
  #floating-panel {
    bottom: 190px !important;
  }
}

@media only screen
and (device-width: 375px)
and (device-height: 812px)
and (-webkit-device-pixel-ratio: 3) {
  #floating-panel {
    bottom: 190px !important;
  }
}

@media only screen
    and (device-width: 390px)
    and (device-height: 844px)
    and (-webkit-device-pixel-ratio: 3) {
  #floating-panel {
    bottom: 190px !important;
  }
}

@media only screen
    and (device-width: 428px)
    and (device-height: 926px)
    and (-webkit-device-pixel-ratio: 3) {
  #floating-panel {
    bottom: 190px !important;
  }
}

@media only screen
    and (device-width: 414px)
    and (device-height: 896px)
    and (-webkit-device-pixel-ratio: 2) {
  #floating-panel {
    bottom: 190px !important;
  }
}

@media only screen
    and (device-width: 375px)
    and (device-height: 812px)
    and (-webkit-device-pixel-ratio: 3) {
  #floating-panel {
    bottom: 190px !important;
  }
}


@media only screen
    and (device-width: 414px)
    and (device-height: 896px)
    and (-webkit-device-pixel-ratio: 3) {
  #floating-panel {
    bottom: 190px !important;
  }
}

@media only screen and (-webkit-device-pixel-ratio: 1)
                   and (max-width: 600px) {
  #floating-panel {
    bottom: 190px !important;
  }
}

@media only screen and (-webkit-device-pixel-ratio: 2)
                   and (max-width: 600px) {
  #floating-panel {
    bottom: 190px !important;
  }
}

@media only screen and (-webkit-device-pixel-ratio: 3)
                   and (max-width: 600px) {
  #floating-panel {
    top: calc(100vh - 350px) !important;
  }
}

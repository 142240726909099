@import 'src/assets/styles/include-media.scss';

.login-container-projects {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 280px;
  max-width: 450px;
  min-height: 220px;
  max-height: 390px;
  width: 370px;
  height: 350px;

  .projects-list {
    display: block;
  }

  #projects-header {
    display: block;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold !important;
    font-size: 18px !important;
    line-height: 25px;
    text-align: center;
    color: #000000;
  }
}

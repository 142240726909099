.spreo-search {
  padding-top: 6px;
}

.input-group.spreo-search .input-group-btn .btn i,
.input-group.spreo-search .input-group-btn .back-btn i {
  color: #0f0f0f;
}

.from-to-cont .spreo-search .direction-search {
  border-color: #00000029;
  border-right: 0;
  height: 36px;
  color: #0f0f0f;
  box-shadow: none;
  transition: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.from-to-cont .spreo-search .form-control[disabled].direction-search {
  background-color: initial;
}

.from-to-cont .spreo-search .btn-default {
  border: 0;
  padding-top: 7px;
  padding-bottom: 7px;
  color: #0f0f0f;
}

.from-to-cont .spreo-search .btn-default:hover,
.from-to-cont .spreo-search .btn-default:active,
.from-to-cont .spreo-search .btn-default:focus,
.from-to-cont .spreo-search .btn-default:active:focus {
  background-color: #fff;
  outline: none;
  color: #0f0f0f;
}

.from-to-cont .spreo-search .btn-default.remove-nav-point:hover,
.from-to-cont .spreo-search .btn-default.remove-nav-point:active,
.from-to-cont .spreo-search .btn-default.remove-nav-point:active:focus {
  border: 0;
}

.from-to-cont .spreo-search .btn-default.remove-nav-point {
  font-size: 25px;
  height: 36px;
  width: 25px;
  margin-left: 5px;
  padding: 0;
}

.from-to-cont .spreo-search .btn-default.favorites-btn {
  font-size: 15px;
  height: 36px;
  width: 30px;
  padding-left: 0;
  padding-right: 10px;
  border: 1px solid #00000029;
  border-left: 0;
  border-radius: 0 4px 4px 0;
}

.from-to-cont .spreo-search .btn-default.favorites-btn.right-dir {
  border-right: none;
  border-left: 1px solid #00000029;
  border-radius: 4px 0 0 4px;
  padding-left: 10px;
  padding-right: 0;
}

.favorites-nav-ico {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: 20px;
}

.mobile-favorites-msg {
  background-color: #fff;
  width: 100%;
  min-height: 50px;
  padding: 10px;
  text-align: center;
  box-shadow: 0 0 6px #00000029;
  z-index: 9000;
  position: absolute;
  top: 0;
}

.spreo-poi-btn i.fa.fa-angle-right,
#instruction-time,
.nav-close-btn {
  color: #0f0f0f;
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.2), 0 0 8px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.2), 0 0 8px rgba(0, 0, 0, 0.2);
}

.space .description {
  color: #0f0f0f;
}

button.all-instructions-btn.focus,
button.all-instructions-btn:focus,
button.all-instructions-btn:hover {
  color: #fff;
}

.form-control:focus {
  box-shadow: none;
  outline: none;
}

.btn.active,
.btn:active {
  box-shadow: none;
}

.map-modal-content {
  display: table;
}

.share-text.selected {
  margin-left: -5px;
}

a.space-operation-btn {
  width: 41px;
  height: 41px;
  line-height: 41px;
  border-radius: 22px;
}

#workstation a.space-operation-btn {
  width: 44px;
  height: 44px;
  line-height: 44px;
}

#workstation .space-btn-go {
  width: 20px;
  height: 20px;
  font-size: 20px;
}

#workstation .space-btn-share {
  font-size: 18px;
}

a.space-operation-btn.btn-go {
  background-color: #5fac45;
  border-color: #5fac45;
}

.quick-logout {
  background: none;
  float: left;
  text-align: left;
}

a {
  text-decoration: none;
}

.login-user {
  position: absolute; /*left: 300px;*/
  z-index: 2001;
  min-height: 46px;
  top: 0px; /* padding-top: 8px; */
  left: 0px;
}

.login-user.show .expandable {
  transition: all 0.1s ease-in-out;
  height: 0;
  visibility: visible;
  position: absolute;
  top: 42px;
  left: 0;
  background: #f5f5f5;
  width: 130px; /*box-shadow: 0 3px 3px 0px rgba(0,0,0,0.5);*/
  border-radius: 0 0 4px 4px;
  padding: 10px;
  text-align: right;
  background: #fff;
}

.login-user.show .expandable {
  height: 50px;
  visibility: visible;
}

.login-user a {
  color: #000;
  display: inline-block;
  padding: 6px 8px 6.5px 8px;
  background: #fff;
  border-radius: 0px;
  padding-right: 30px;
}

.login-user a .glyphicon-user {
  background: #e5e2e2;
  padding: 10px;
  border-radius: 50px;
  margin-right: 10px;
}

.buildings-list {
  left: 190px;
}

.user-arrow {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.modal-1 {
  text-align: center;
  padding: 0 !important;
}

.modal-1:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-1 .modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.styled-checkbox {
  position: absolute;
  opacity: 0;
}

.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
  color: #000000;
}

.styled-checkbox + label:before {
  content: '';
  margin-right: 7px;
  display: inline-block;
  vertical-align: text-top;
  width: 16px;
  height: 16px;
  background: white;
  border: 1px solid #000;
  margin-top: 2px;
}

.styled-checkbox:hover + label:before {
  background: transparent;
}

.styled-checkbox:focus + label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.styled-checkbox:checked + label:before {
  background: #000;
}

.styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}

.styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}

.styled-checkbox:checked + label:after {
  content: '';
  position: absolute;
  left: 3px;
  top: 9px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}

/*CSS 14-JUL-2020*/

/*NEW Design Css*/
.login-user .expandable {
  display: none;
}

.login-user.show .expandable {
  display: block;
  width: 100%;
}

.select-one {
  background: #fff;
  padding: 16px 12px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 9;
  width: 50px;
  border-radius: 0 5px 5px 0;
  box-shadow: 0 0 5px #00000029;
}

.select-one ul,
.campus-modal ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

.select-one ul li,
.campus-modal ul li {
  margin-bottom: 19px;
}

.select-one ul li {
  text-align: center;
}

.select-one ul li img {
  max-width: 20px;
}

.campus-modal ul li img {
  max-width: 23px;
}

.select-one ul li:last-child,
.campus-modal ul li:last-child {
  margin-bottom: 0;
}

.select-one ul li a {
  text-decoration: none;
  color: transparent;
}

.select-one ul li a:hover,
.select-one ul li a:focus {
  outline: 0;
}

.login-user .expandable i.fa-sign-out {
  margin-right: 5px;
}

.modal-width,
#category-filter .modal-content {
  max-width: 340px;
  width: 100%;
  padding: 0 !important;
}

.checkbox-title {
  display: inline-block;
  margin-left: 10px;
}

.remeber {
  padding: 0 15px 0;
  margin: 0 15px 0;
}

.campus-modal ul {
  margin: 15px 0;
}

.campus-modal h4 {
  color: #000;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  margin: 20px 0 15px;
}

.campus-modal ul li {
  text-align: center;
  margin-bottom: 5px;
}

.campus-modal ul li a {
  font-weight: 500;
}

.campus-modal ul.category-list {
  max-height: 430px;
  margin: 15px 20px 15px 35px;
}

.campus-modal ul.category-list .checkbox {
  margin-bottom: 18px;
}

.campus-modal ul.category-list .checkbox label {
  padding-left: 30px;
}

.campus-modal ul.category-list li {
  text-align: left;
}

.campus-modal ul.category-list.right-dir li {
  text-align: right;
}

.campus-modal ul.category-list.right-dir .checkbox label {
  padding-right: 20px;
  padding-left: 0;
}

.campus-modal ul.category-list.right-dir .black-checkbox span {
  right: 0;
}

.close-campus {
  text-align: center;
  padding: 10px 15px;
  margin: 30px 15px 0;
  font-size: 16px;

  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 38px 8px;
    position: static;
    width: 221px;
    height: 34px;
    left: 0px;
    top: 0px;
    background: #ffffff;
    border: 1px solid #5fac45;
    box-sizing: border-box;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 1;
    margin: 0px 16px;
    color: black;

    &:hover {
      text-decoration: none;
      color: black;
    }
  }
}

.close-campus-static {
  text-align: center;
  padding: 10px 15px;
  margin: 15px 15px 0;
  font-size: 16px;
}

.close-campus a {
  color: #000;
}

.default-label {
  font-weight: 500;
  font-size: 14px;
  margin: 0;
}

.new-map-icon {
  position: absolute;
  z-index: 9;
  top: 65px;
  left: 5px;
}

.select-one ul li img.build-icon {
  max-width: 19px;
}

.new-map-icon img {
  max-width: 50px;
}

.campus-modal ul.map_key_modal {
  padding: 0 15px;
  margin-bottom: 25px;
}

.campus-modal ul.map_key_modal li {
  text-align: left;
  margin-bottom: 5px;
  line-height: 28px;
  color: #000;
  vertical-align: middle;
}

.campus-modal ul.map_key_modal li img {
  margin-right: 12px;
}

#campus.modal.in {
  background-color: #fff;
}

#neighbour-circle {
  clear: both;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 15px 0;
  color: #000;
  position: relative;
  top: 15px;
}

#neighbour-circle h4 {
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  margin: 0 0 15px;
  text-align: left;
  padding-left: 10px;
  font-weight: 600;
}

#neighbour-circle .row {
  margin: 0;
}

.favorites-paragraph {
  text-align: left;
  border-bottom: 1px solid #9a9a9a;
  padding-bottom: 10px;
  margin-bottom: 0;
  font-size: 14px;
  color: #000;
}

#favorites-ui-window.spreo-ui-window img.poi-img {
  height: 30px;
  width: 30px;
}

h3.ocupancy-number {
  text-align: center;
  color: #000;
  margin-bottom: 5px;
}

p.ocupancy-heading {
  text-align: center;
  color: #000;
  font-size: 12px;
}

.first-circle {
  width: 70px;
  height: 70px;
  border: 3px solid #61e24b;
  border-radius: 50%;
  padding: 0;
  position: relative; /* clip: rect(0em, 1em, 1em, 0.5em); */
  background: transparent;
  margin-bottom: 7px;
  margin: 0 auto 7px;
}

.first-circle-red {
  width: 70px;
  height: 70px;
  border: 3px solid red;
  border-radius: 50%;
  padding: 0;
  position: relative; /* clip: rect(0em, 1em, 1em, 0.5em); */
  background: transparent;
  margin-bottom: 7px;
  margin: 0 auto 7px;
}

.second-circle {
  width: 70px;
  height: 70px;
  border: 8px solid #61e24b;
  border-radius: 50%;
  margin: 7px auto 0;
  position: relative;
  clip: rect(0em, 1em, 1em, 0.5em);
  background: transparent;
}

.second-circle:after {
  content: '';
  background: transparent;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  border: 8px solid red;
  border-radius: 50%;
  top: -8px;
  left: -8px;
  clip: rect(0, 6.5em, 3em, 3em);
}

.circle-number {
  width: 50px;
  height: 50px;
  line-height: 54px;
  color: #000;
  font-size: 18px;
  text-align: center;
  margin: 0 auto;
  display: block;
  font-weight: 700;
}

.progress-circle {
  position: relative;
  display: inline-block;
  margin: 7px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #61e24b;
}

.progress-circle:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation: colorload 2s;
  animation: colorload 2s;
}

.progress-circle span {
  font-size: 14px;
  color: #000;
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: -20px;
  margin-top: -20px;
  text-align: center;
  border-radius: 50%;
  background: #fff;
  z-index: 1;
  font-weight: 600;
}

.progress-circle span:after {
  content: '%';
  font-weight: 600;
  color: #000;
}

.progress-circle.progress-0:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent), linear-gradient(90deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-1:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(93.6deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-2:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(97.2deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-3:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(100.8deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-4:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(104.4deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-5:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(108deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-6:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(111.6deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-7:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(115.2deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-8:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(118.8deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-9:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(122.4deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-10:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(126deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-11:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(129.6deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-12:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(133.2deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-13:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(136.8deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-14:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(140.4deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-15:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(129.6deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-16:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(133.2deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-17:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(136.8deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-18:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(140.4deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-19:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(144deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-20:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(151.2deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-21:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(158.6deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-22:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(161.2deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-23:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(165.6deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-24:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(169.2deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-25:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(180deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-26:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(183.6deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-27:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(187.2deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-28:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(190.8deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-29:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(194.4deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-30:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(198deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-31:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(201.6deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-32:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(205.2deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-33:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(208.8deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-34:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(212.4deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-35:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(216deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-40:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(234deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-36:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(219.6deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-37:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(223.2deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-38:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(226.8deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-39:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(230.4deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-41:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(237.6deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-42:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(241.2deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-43:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(244.8deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-44:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(248.4deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-45:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(252deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-46:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(255.6deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-47:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(259.2deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-48:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(262.8deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-49:after {
  background-image: linear-gradient(90deg, #61e24b 50%, transparent 50%, transparent),
    linear-gradient(266.4deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-50:after {
  background-image: linear-gradient(-90deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-51:after {
  background-image: linear-gradient(-86.4deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-52:after {
  background-image: linear-gradient(-82.6deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-53:after {
  background-image: linear-gradient(-79.2deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-54:after {
  background-image: linear-gradient(-75.6deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-55:after {
  background-image: linear-gradient(-72deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-56:after {
  background-image: linear-gradient(-68.4deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-57:after {
  background-image: linear-gradient(-64.8deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-58:after {
  background-image: linear-gradient(-61.2deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-59:after {
  background-image: linear-gradient(-57.6deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-60:after {
  background-image: linear-gradient(-54deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-61:after {
  background-image: linear-gradient(-50.4deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-62:after {
  background-image: linear-gradient(-46.8deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-63:after {
  background-image: linear-gradient(-43.2deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-64:after {
  background-image: linear-gradient(-39.6deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-65:after {
  background-image: linear-gradient(-36deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-66:after {
  background-image: linear-gradient(-32.4deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-67:after {
  background-image: linear-gradient(-28.8deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-68:after {
  background-image: linear-gradient(-25.2deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-69:after {
  background-image: linear-gradient(-21.6deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-70:after {
  background-image: linear-gradient(-18deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-71:after {
  background-image: linear-gradient(-14.4deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-72:after {
  background-image: linear-gradient(-10.8deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-73:after {
  background-image: linear-gradient(-7.2deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-74:after {
  background-image: linear-gradient(-3.6deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-75:after {
  background-image: linear-gradient(0deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-76:after {
  background-image: linear-gradient(3.6deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-77:after {
  background-image: linear-gradient(7.2deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-78:after {
  background-image: linear-gradient(10.8deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-79:after {
  background-image: linear-gradient(14.4deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-80:after {
  background-image: linear-gradient(18deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-81:after {
  background-image: linear-gradient(21.6deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-82:after {
  background-image: linear-gradient(25.2deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-83:after {
  background-image: linear-gradient(28.8deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-84:after {
  background-image: linear-gradient(32.4deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-85:after {
  background-image: linear-gradient(36deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-86:after {
  background-image: linear-gradient(39.2deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-87:after {
  background-image: linear-gradient(43.2deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-88:after {
  background-image: linear-gradient(46.8deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-89:after {
  background-image: linear-gradient(50.4deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-90:after {
  background-image: linear-gradient(54deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-91:after {
  background-image: linear-gradient(57.6deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-92:after {
  background-image: linear-gradient(61.2deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-93:after {
  background-image: linear-gradient(64.8deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-94:after {
  background-image: linear-gradient(68.4deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-95:after {
  background-image: linear-gradient(72deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-96:after {
  background-image: linear-gradient(75.6deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-97:after {
  background-image: linear-gradient(79.2deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-98:after {
  background-image: linear-gradient(82.8deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-99:after {
  background-image: linear-gradient(86.4deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

.progress-circle.progress-100:after {
  background-image: linear-gradient(90deg, red 50%, transparent 50%, transparent), linear-gradient(270deg, red 50%, #61e24b 50%, #61e24b);
}

@-webkit-keyframes colorload {
  0% {
    opacity: 0;
    transform: rotate(0deg);
  }
  100% {
    opacity: 1;
    transform: rotate(360deg);
  }
}

.neighbour-detail {
  text-align: left;
  margin-top: 28px;
}

p.text-grey {
  color: #b2b2b2;
  margin-top: 15px;
  font-style: italic;
}

.spreo-nav-window button:last-child {
  /*margin-bottom: 40px;*/
}

.spreo-ui-window img.poi-img {
  height: 25px;
  border-radius: 0;
  margin-right: 5px;
  margin-top: 6px;
}

.print-load {
  margin-top: 50px !important;
  text-align: center;
}

@-moz-document url-prefix() {
  .gm-style-iw-d {
    overflow: visible !important;

    div {
      width: 110%;
    }
  }
}

.gm-style .you-are-here .gm-style-iw-d > div {
  padding: 10px 17px 8px 17px;
}

@media (max-width: 767px) {
  .login-user {
    position: relative; /*left: 300px;*/
    z-index: 2001;
    min-height: 46px; /* padding-top: 8px; */
  }

  #instruction-time {
    padding-top: 4px;
    margin-right: 15px;
    margin-left: -30px;
  }

  .instruction-cont {
    // margin-top: 10px;
  }

  .print-email {
    margin-left: -7px;
  }

  .login-user {
    width: 100%;
    top: 0px;
    left: 0px;
  }

  .login-user a {
    width: 100%;
    border-radius: 0;
  }

  .login-user .expandable {
    width: 100%;
    display: none;
  }

  .login-user.show .expandable {
    display: block;
    width: 100%;
  }

  .tab-overlay-desktop .overlay-spin.map {
    top: 40% !important;
    left: 33% !important;
  }

  #map {
    height: 100vh !important;
  }

  .spreo-category-btn {
    font-size: 15px;
  }

  .buildings-list.mobile .dropdown-menu {
    left: 0;
  }

  .mobile-search.nav {
    z-index: 301;
    box-shadow: 0 0 2px #00000029;
  }

  .tab-overlay {
    z-index: 301;
  }

  .select-one ul,
  .campus-modal ul {
    padding: 0;
    list-style: none;
    margin: 0;
  }

  .select-one ul li,
  .campus-modal ul li {
    margin-bottom: 19px;
  }

  .select-one ul li:last-child,
  .campus-modal ul li:last-child {
    margin-bottom: 0;
  }

  .select-one ul li a {
    text-decoration: none;
    color: transparent;
  }

  .select-one ul li a:hover,
  .select-one ul li a:focus {
    outline: 0;
  }

  .moadl-width {
    max-width: 320px;
    width: 100%;
  }

  .remeber {
    padding: 0 15px 0;
    margin: 0 15px 0;
  }

  .campus-modal ul {
    margin: 15px 0;
    font-size: 16px;
  }

  .campus-modal ul li {
    text-align: center;
    margin-bottom: 5px;
  }

  .campus-modal ul li a {
    font-weight: 500;
  }

  .default-label {
    font-weight: 500;
    font-size: 16px;
    margin: 0;
  }

  .new-map-icon {
    position: absolute;
    z-index: 9;
    top: 80px;
    left: 5px;
  }

  .campus-modal ul.map_key_modal {
    padding: 0 15px;
    margin-bottom: 25px;
  }

  .campus-modal ul.map_key_modal li {
    text-align: left;
    margin-bottom: 5px;
    line-height: 28px;
    color: #000;
    vertical-align: middle;
  }

  .campus-modal ul.map_key_modal li img {
    margin-right: 12px;
  }

  #neighbour-circle {
    clear: both;
    border-top: 1px solid #9a9a9a;
    border-bottom: 1px solid #9a9a9a;
    padding: 15px 0;
    color: #000;
    position: relative;
    top: 15px;
  }

  #neighbour-circle h4 {
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
    color: #000;
    font-weight: 500;
    margin: 0 0 15px;
    text-align: left;
    padding-left: 10px;
  }

  #neighbour-circle .row {
    margin: 0;
  }

  h3.ocupancy-number {
    text-align: center;
    color: #000;
    margin-bottom: 5px;
  }

  p.ocupancy-heading {
    text-align: center;
    color: #000;
  }

  .favorites-paragraph {
    text-align: left;
    border-bottom: 1px solid #9a9a9a;
    padding-bottom: 10px;
    margin-bottom: 0;
    font-size: 14px;
    color: #000;
  }

  #favorites-ui-window .spreo-poi-btn {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  #favorites-ui-window a.space-operation-btn {
    display: none;
  }

  #favorites-ui-window a.space-operation-btn.del-favorite {
    display: none;
  }

  .spreo-poi-btn {
    font-size: 15px;
  }

  button.all-instructions-btn {
    margin-bottom: 30px;
  }

  .instruction-divider {
    // margin-right: 12px;
  }

  #floating-panel {
    z-index: 300;
    position: fixed;
    bottom: 0;
    right: 0;
    min-height: auto;
  }

  #floating-panel .page-arrow.next-butt {
    margin-top: 5px;
  }

  .gm-style .gm-style-iw-c {
    padding: 0 !important;
  }

  .gm-style .gm-style-iw-d {
    overflow: auto !important;
  }

  .gm-style .gm-style-iw-d > div {
    padding: 10px 17px 8px 17px;
  }

  [lang='he'] .gm-style .gm-style-iw-d > div {
    padding: 10px 17px 8px 17px;
  }

  [lang='he'] .gm-style .gm-style-iw-d {
    margin-left: 0;
    margin-right: 20px;
    padding-left: 10px;
  }

  .spreo-nav-window .instruction-divider:first-child {
    margin-top: 50px;
  }

  #favorites-ui-window.spreo-ui-window img.poi-img {
    height: 35px;
    width: 35px;
  }

  .print-load {
    margin-top: 50px !important;
    text-align: center;
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      #floating-panel .page-arrow.next-butt {
        margin-top: 9px;
      }

      .select-one ul li a:hover,
      .select-one ul li a:focus {
        outline: 0;
      }
    }
  }
}

@media (max-width: 400px) {
  .spreo-ui-window {
    padding-right: 0;
    margin-top: 3px;
    height: 100%;
  }

  .space-btn-map-ico {
    color: black;
    width: 16px;
    height: 16px;
    font-size: 20px;
    margin-top: -2px;
  }

  .space-btn-map-ico {
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  #instruction-time {
    margin-left: -50px;
  }
}

.pt-12 {
  padding-top: 12px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

/*
.pt-12 button:last-child {
  margin-bottom: 40px;
}
.pt-30 button:last-child {
  margin-bottom: 40px;
}
.pt-50 button:last-child {
  margin-bottom: 40px;
}
*/
.mr-35 {
  margin-top: 35px !important;
}

.mr-45 {
  margin-top: 45px !important;
}

.mr-50 {
  margin-top: 50px !important;
}

.mr-65 {
  margin-top: 65px !important;
}

.mr-70 {
  margin-top: 70px !important;
}

.mr-95 {
  margin-top: 95px !important;
}

.mr-120 {
  margin-top: 120px !important;
}

#poi-title div {
  max-width: 85%;
}

.from-to-cont {
  margin-top: 20px;
}

.spreo-nav-window.nav-collapsed {
  margin-top: 10px !important;
  padding-top: 0 !important;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  #srch-termfrom,
  #srch-termto {
    float: left;
  }

  button.spreo-remove {
    float: left;
  }

  .direction-icon {
    margin-top: 5px;
  }

  .nav-close-btn.desk {
    margin-top: 50px;
  }

  .from-to-cont {
    margin-top: 30px;
  }

  .spreo-ui-window {
    height: 89%;
  }
}

.black-checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  height: 16px;
  clear: both;
}

@media (max-width: 767px) {
  .black-checkbox {
    font-size: 16px;
  }

  #map-help .campus-modal p,
  #workstation .campus-modal p {
    font-size: 15px;
  }

  #printModal .print-radio {
    font-size: 16px;
  }
}

.black-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.black-checkbox span {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border-radius: 2px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  border: 2px solid #c8c8c8;
}

.black-checkbox input:checked ~ .span {
  background-color: #000;
  border-radius: 2px;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity: 1;
  border: 2px solid #fff;
}

.black-checkbox span::after {
  position: absolute;
  content: '';
  left: 8px;
  top: 8px;
  height: 0;
  width: 0;
  border-radius: 2px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  opacity: 1;
}

.black-checkbox input:checked ~ span {
  border-color: #000;
  background-color: #000;
}

.black-checkbox input:checked ~ span::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 3px;
  top: -2px;
  width: 7px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}

button {
  .spreo-btn-loader {
    color: white;
    margin-right: 5px;
  }
}

.tab-overlay-desktop.map {
  top: 0px;
  height: 100%;
}

.tab-overlay {
  display: block;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 1040;
}

.tab-overlay-desktop {
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
  position: absolute !important;
  top: 0px !important;
  left: 0 !important;
  z-index: 1040 !important;
}

.tab-overlay-desktop .overlay-spin.map {
  top: 40%;
  left: 47%;
}

.tab-overlay-desktop .overlay-spin {
  display: block;
  position: absolute;
  top: 23%;
  left: 33%;
}
.tab-overlay .overlay-spin {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader-width {
  width: 120px;
}

.print-load {
  margin-top: 50px !important;
  text-align: center;
  font-style: italic;
}

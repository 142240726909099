#howto {
  &.modal-dialog {
      max-width: 604px;
  }

  .modal-width {
    min-width: 100%;
  }

  .modal-content {
    border-radius: 15px;
  }

  .modal-footer {
    padding: 24px;

    .action-buttons {
      display: flex;
      justify-content: end;
      align-items: center;
      width: 100%;

      &.two-sides {
        justify-content: space-between;

        .left {
          justify-content: start;
          display: flex;
        }

        .right {
          display: flex;
          justify-content: end;
          gap: 8px;
        }
      }

      button {
        display: flex;
        height: 40px;
        padding: 0 16px;
        align-items: center;
        gap: 16px;
        border-radius: 4px;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 0.5px;
        text-transform: capitalize;
        color: #000;
        border: 0;
        background: initial;
        cursor: pointer;

        &.filled-btn {
          background-color: #000;
          color: #fff;
        }
      }
    }
  }

  .howto-modal {
    padding: 24px 24px 0;

    .page {
      .headline {
        font-family: Rubik;
        font-size: 24px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        color: #1D1B20;
      }

      .page-img {
        > [class^="page-"] {
          display: inline-block;
          width: 100%;
          height: 281px;
        }

        &.desktop {
          .page-1 {
            background-image: url("../../assets/images/howto/page1-desk.png");
          }

          .page-2 {
            background-image: url("../../assets/images/howto/page2-desk.png");
          }

          .page-3 {
            background-image: url("../../assets/images/howto/page3-desk.png");
          }

          .page-4 {
            background-image: url("../../assets/images/howto/page4-desk.png");
          }
        }

        &.mobile {
          margin-left: -24px;
          margin-right: -24px;
          margin-top: -24px;

          > [class^="page-"] {
            height: calc(281 / 360 * 100vw);
            background-size: cover;
          }

          .page-1 {
            background-image: url("../../assets/images/howto/page1-mob.png");
          }

          .page-2 {
            background-image: url("../../assets/images/howto/page2-mob.png");
          }

          .page-3 {
            background-image: url("../../assets/images/howto/page3-mob.png");
          }

          .page-4 {
            background-image: url("../../assets/images/howto/page4-mob.png");
          }

          .page-5 {
            background-image: url("../../assets/images/howto/page5-mob.png");
          }

          .page-6 {
            background-image: url("../../assets/images/howto/page6-mob.png");
          }

          .page-7 {
            background-image: url("../../assets/images/howto/page7-mob.png");
          }
        }
      }

      .page-descr {
        font-family: Open Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #49454F;
        padding-top: 15px;
        white-space: pre-line;
        min-height: 185px;
      }
    }
  }

  &.mobile {
    &.modal-dialog {
      margin: 0;
    }

    .modal-content {
      border: 0;
      min-height: 100%;
      min-width: 100vw;
      border-radius: 0;
    }

    .modal-footer {
      border-top: 0;
    }

    .howto-modal {
      .page {
        .headline {
          margin-top: 20px;
        }

        .page-descr {
          min-height: 210px;
        }

        .close-btn {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          width: 40px;
          height: 40px;
          padding: 8px;
          border-radius: 20px;
          border: 0;
          cursor: pointer;
          background: #FFF;
          filter: drop-shadow(0px 0px 8.850000381469727px rgba(0, 0, 0, 0.16));
          position: fixed;
          top: 17px;
          left: 17px;

          .close-ico {
            display: block;
            background-image: url('../../assets/images/others/close-main-menu.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}
/*
* * * * * * * * * * * *
* Spreo button classes
* * * * * * * * * * * *
*/
button.spreo-login-btn {
  padding: 9px 48px;
  background-color: #fff;
  font-size: 18px;
  color: #000000;
  font-weight: 600;
  font-family: 'Open Sans';
  border: 0;
  border-radius: 10px;
  box-shadow: 0 0 6px #00000029;
}

button.spreo-login-btn:active {
  color: #000000;
  background-color: #fff;
  border: 0;
  box-shadow: 0 0 6px #00000029;
}

button.spreo-login-btn:hover,
button.spreo-login-btn:focus {
  color: #000000;
  background-color: #fff;
  border: 0;
  box-shadow: 0 0 10px #00000029;
}

.spreo-poi-span {
  display: block;
  font-size: 12px;
  color: #666666;
  margin-left: 30px;
  margin-top: -5px;
}

.spreo-poi-occupied-by {
  margin-left: 45px;
}

.spreo-category-btn {
  background-color: #ffffff;
  color: #000000;
  border: 0 solid transparent;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
}

.spreo-category-btn:last-child {
  border-bottom: 0;
}

.btn-default.spreo-category-btn.logout-cat-btn.top-border:hover,
.btn-default.spreo-category-btn.logout-cat-btn.top-border:focus {
  border-top: 1px ridge;
}

.spreo-category-btn span.fa.fa-angle-right {
  color: #777;
  line-height: 20px;
}

.spreo-category-btn.btn-block + .spreo-category-btn.btn-block {
  margin-top: 0;
}

.spreo-poi-btn {
  border: 0;
  border-bottom: 1px solid;
  white-space: normal;
  display: block;
  padding-top: 13px;
  padding-bottom: 13px;
  font-size: 14px;
  border-bottom-color: #9a9a9a;
  padding-right: 15px;
}

.spreo-poi-btn.space-btn {
  padding: 10px 5px;
  font-size: 14px;
}

.btn-book {
  color: #0f0f0f;
  background-color: #fff;
  border-color: #0f0f0f;
}

.btn-block.claim {
  border-color: #2dcf40;
}

.btn-book.release {
  background-color: #fff;
  border-color: #f9ad15;
  padding: 5px;
}

.spreo-poi-btn.space-btn button {
  font-size: 10px;
  color: #0f0f0f;
  background-color: #fff;
  border-color: #0f0f0f;
}

.spreo-poi-btn.space-btn button.claim {
  border-color: #2dcf40;
}

.spreo-poi-btn.space-btn button.release {
  background-color: #fff;
  border-color: #f9ad15;
}

.spreo-filter {
  margin-top: 7px;
  background-color: #fff;
  width: 20px;
  height: 20px;
  padding: 0;
  border: 0;
}

/* For Mobile increase font sizes */
@media (max-width: 767px) {
  button.spreo-breadCrum {
    font-size: 14px;
  }

  .spreo-poi-span {
    font-size: 12px;
  }

  .modal-profile-users .spreo-poi-btn {
    padding-top: 8px;
    padding-bottom: 8px;
    line-height: normal;
  }

  .spreo-poi-btn.space-btn {
    font-size: 14px;
  }

  .spreo-poi-btn.space-btn button.btn-danger {
    font-size: 12px;
  }

  .friends-window {
    margin-top: 30px;
  }
}

.spreo-poi-btn.space-btn .add-btn {
  margin-right: 25px;
  margin-top: 0;
  font-size: 20px;
  font-weight: normal;
}

.spreo-poi-btn.btn-block + .spreo-poi-btn.btn-block {
  margin-top: 0;
}

.spreo-poi-btn span.poi-descr {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 215px;
  display: inline-block;
  overflow: hidden;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .spreo-poi-btn span.poi-descr {
    max-width: 165px;
  }
}

/*
* Booking Pois (Meeting rooms) availability icon
*/
.spreo-poi-btn span.poi-descr .availability-icon {
  margin-right: 5px;
  font-size: 10px;
  display: block;
  margin-top: 4px;
  float: left;
}

.spreo-poi-btn span.poi-descr .availability-icon.no-data {
  color: #fff;
  display: none;
}

.spreo-poi-btn span.poi-descr .availability-icon.available {
  color: #00e500;
}

.spreo-poi-btn span.poi-descr .availability-icon.not-available {
  color: #ff0000;
}

.spreo-poi-btn span.poi-descr .availability-icon.in-use {
  color: #989898;
}
/*
* Button images
*/
.spreo-poi-btn img {
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 5px;
}

.image-crop {
  margin-right: 10px;
  margin-top: 5px;
  width: 25px;
  height: 25px;
  position: relative;
  overflow: hidden;
  border-radius: 0 !important;
}

.image-crop.right-dir {
  margin-right: 0;
  margin-left: 10px;
}

.image-crop img {
  display: inline;
  height: 25px;
  width: auto;
  margin-top: 0;
  margin-left: 0;
  border-radius: 0;
}

.image-crop.right-dir img {
  margin-right: 0;
}

.modal-profile-users .spreo-poi-btn img {
  margin-top: 0;
}

.modal-profile-users .add-user {
  margin-top: 4px;
}

.spreo-poi-btn img.space-ico {
  width: 25px;
  height: 25px;
}

.spreo-poi-btn i.fa.fa-angle-right {
  color: #777;
  line-height: 35px;
  margin-right: 5px;
  font-size: 20px;
}

.spreo-poi-btn i.fa.fa-angle-left {
  color: #777;
  line-height: 35px;
  margin-left: 5px;
  font-size: 20px;
}

.spreo-poi-btn .more-info i.fa.fa-angle-right {
  line-height: normal;
  margin-left: 5px;
  margin-right: 0;
  vertical-align: -1px;
}

.spreo-poi-btn:last-child {
  border-bottom: 0;
}

.spreo-branding {
  height: 40px;
  bottom: 2%;
  margin: 0 auto;
}

.spreo-tab {
  position: absolute;
  height: 30px;
  right: 0;
  bottom: 2%;
  margin: 0 10px 5px;
}
.spreo-ui-window,
.spreo-nav-window {
  flex: 2;
}
@media (max-height: 860px) {
  .spreo-ui-window {
    height: 98%;
    // overflow-y: hidden;
  }
}
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-top: 13px;
  margin-right: 10px;
  margin-left: 5px;
}

.dot-red {
  background-color: #ff0000;
}

.dot-green {
  background-color: #00e500;
}

.dot-yellow {
  background-color: #fce80a;
}

.dot-gray {
  background-color: #b2b2b2;
}

.text-gray {
  color: gray !important;
}

.matched-keywords {
  font-weight: bold;
  margin-top: 0;
  font-size: 11px;
}

.matched-keywords.right-dir {
  margin-right: 35px;
}

.matched-keywords span {
  font-style: italic;
  color: #666666;
}

.show-in-map.hidden {
  visibility: hidden;
  display: none;
}
@import 'src/assets/styles/include-media.scss';

.login-btn {
  padding: 9px 48px;
  background-color: #fff;
  font-size: 18px;
  color: #000000;
  font-weight: 600;
  font-family: 'Open Sans';
  border: 0;
  border-radius: 10px;
  box-shadow: 0 0 6px #00000029;
}

.login-input-icon {
  background-color: #fff;
  border-color: #C8C8C8;
  border-right: 0;
  border-radius: 7px 0 0 7px;
}
.login-input {
  border-left: 0;
  height: 50px;
  border-color: #707070;
  border-radius: 7px;
  font-size: 16px;
  padding: 14px 20px 14px 8px;
}
.login-input:-internal-autofill-selected {
  background-color: rgb(232, 240, 254);
}
.login-btn-container {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 110px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: 100%;
  position: relative;
  z-index: 10000;

  @include media('<=phone') {
    width: 100%;
  }

  button {
    width: 100% !important;
    height: 34px;

    @include media('<=phone') {
      width: 96%;
    }
  }
}
.form-label {
  margin-bottom: 16px;
  margin-top: 1rem;

  span {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: #4D4D4D;
  }
}

.login-error {
  display: inline-block;
  color: #D52744;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  padding: 5px;
}

@media only screen
       and (max-height: 320px) {
  .login-btn-container {
    top: unset;
  }

  .spreo-login-form {
    height: 320px !important;
  }
}

.spreo-map {
  width: 100%;
  height: 100vh;
  position: absolute;
  overflow: hidden;
}

a[href^="http://maps.google.com/maps"] {
  display: none !important;
}

a[href^="https://maps.google.com/maps"] {
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}

.spreo-label {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  line-height: 16px;
  text-align: center;
  word-wrap: break-word;
  /* text-shadow: -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff; */
  white-space: initial;
  margin-top: 65px;
  padding: 8px;
  background: rgba(255, 255, 255, 0.65);;
  z-index: -500;
  border-radius: 10px;
  position: relative;
  width: 110px;
  cursor: pointer;
}

.spreo-small-label {
  width: 90px !important;
}

.spreo-big-label {
  margin-top: 64px;
}

/*
    Google Map Controls Desktop Right
    Right Side, includes transition speed and widths based on screen width
*/

@media (min-width: 768px) {
  .sidebar-right ~ .spreo-map .gm-style > div.gmnoprint[style*='right: 28px'],
  .sidebar-right ~ .spreo-map #floating-panel {
    transition: margin-right 500ms;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .sidebar-right ~ .spreo-map .gm-style > div.gmnoprint[style*='right: 28px'],
  .sidebar-right ~ .spreo-map #floating-panel {
    margin-right: 345px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .sidebar-right ~ .spreo-map .gm-style > div.gmnoprint[style*='right: 28px'],
  .sidebar-right ~ .spreo-map #floating-panel {
    margin-right: 395px !important;
  }
}

@media (min-width: 1400px) {
  .sidebar-right ~ .spreo-map .gm-style > div.gmnoprint[style*='right: 28px'],
  .sidebar-right ~ .spreo-map #floating-panel {
    margin-right: 395px !important;
  }
}

@media (min-width: 768px) {
  .sidebar-right.collapsed ~ .spreo-map .gm-style > div.gmnoprint[style*='right: 28px'],
  .sidebar-right.collapsed ~ .spreo-map #floating-panel {
    margin-right: 10px !important;
  }

  .spreo-map #floating-panel {
    margin-right: 10px !important;
  }
}

.gmnoprint {
  position: fixed !important;
  right: 22px !important;
  left: unset !important;
  bottom: 245px !important;
  top: unset !important;
}

.gmnoprint div:first-child {
  border-radius: 28px !important;
  box-shadow: 0 0 8.85px rgba(0, 0, 0, 0.08) !important;
}

@media (max-width: 768px) {
  .gmnoprint {
    position: fixed !important;
    right: 22px !important;
    bottom: 130px !important;
    transition: bottom 400ms ease-in-out;
  }

  .spreo-map.third-screen {
    .gmnoprint {
      //bottom: 52% !important;
      top: calc(50vh - 80px) !important;
      bottom: auto !important;
    }
  }

  .spreo-map.third-screen-route {
    .gmnoprint {
      bottom: 48% !important;
    }
  }
}

@media (orientation: landscape) and (max-width: 900px) {
  .gmnoprint {
    bottom: 145px !important;
    right: 26px !important;
  }
}

.gm-ui-hover-effect {
  outline: none !important;
}
